import React from "react";

// App
import {
  PTSerif,
  LaLuxesScript,
  LaLuxesSerif,
} from "../../Typography/LaLuxesScript/LaLuxesScript";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Material UI
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

// images
import background from "../../../images/png/rough_cream_background_medium_opt.png";
import background_webp from "../../../images/png/rough_cream_background_medium.webp";

const CssGrid = withStyles({
  root: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    gridTemplateRows: "repeat(12, auto)",
  },
})(Container);

const Flexbox = withStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    alignItems: "center",
  },
})(Box);

const Item = withStyles({})(Box);

const useStyles = makeStyles((theme) => ({
  responsive: {
    width: "100%",
    height: "auto",
  },
  cssGrid: {
    [theme.breakpoints.up("sm")]: {},

    [theme.breakpoints.up("md")]: {},

    [theme.breakpoints.up("lg")]: {},
  },

  itemTopBackgroundImage: {
    gridColumn: "1/-1",
    gridRow: "1/-1",

    alignSelf: "center",
    justifySelf: "center",
  },

  itemTitle: {
    gridColumn: "1/-1",
    gridRow: "1",

    alignSelf: "center",
    justifySelf: "center",
  },

  itemBody: {
    gridColumn: "1/-1",
    gridRow: "2",
  },

  itemNumber: {
    gridColumn: "1/-1",
    gridRow: "3",
  },
}));

export default function ShuttleService() {
  const classes = useStyles({});

  return (
    <>
      <CssGrid className={classes.cssGrid} maxWidth="lg" disableGutters>
        <Item className={classes.itemTopBackgroundImage}>
          <picture>
            <source type="image/webp" srcSet={background_webp} />
            <img
              loading="lazy"
              className={classes.responsive}
              src={background}
              alt="sand background"
            />
          </picture>
        </Item>

        <Item className={classes.itemTitle} pt={3}>
          <Flexbox className={classes.itemSunrise}>
            <LaLuxesSerif fontsize={14}>
              <Typography
                color="error"
                variant="h2"
                style={{ marginLeft: "-1.5rem" }}
              >
                SHU<span style={{ textDecoration: "underline" }}>TTLE</span>
              </Typography>
            </LaLuxesSerif>

            <Item pl={2}>
              <LaLuxesScript fontsize={14}>
                <Typography color="error" variant="h1">
                  service
                </Typography>
              </LaLuxesScript>
            </Item>
          </Flexbox>
        </Item>

        <Item className={classes.itemBody} px={2} py={1}>
          <PTSerif fontsize={16}>
            <Typography color="secondary" variant="h5" paragraph>
              We are currently in contact with Florida Keys Shuttle to determine
              the shuttle service rates from the Airports to Islamorada.
            </Typography>
          </PTSerif>
        </Item>

        <Item className={classes.itemNumber} pt={1}>
          <PTSerif fontsize={16}>
            <Typography color="secondary" variant="h5" paragraph>
              <FontAwesomeIcon icon={["fal", "bus"]} size="2x" />
            </Typography>
          </PTSerif>
        </Item>
      </CssGrid>
    </>
  );
}
