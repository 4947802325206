import React from "react";

// App
import {
  PTSerif,
  LaLuxesScript,
} from "../../Typography/LaLuxesScript/LaLuxesScript";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Material UI
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

const Grid = withStyles({
  root: {
    display: "grid",
    rowGap: "20px",
  },
})(Container);

const Flexbox = withStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    alignItems: "center",
  },
})(Box);

const Paragraph = withStyles({
  root: {
    textAlign: "left",
  },
})(Container);

const Item = withStyles({})(Box);

const useStyles = makeStyles((theme) => ({
  outerLayout: {
    gridTemplateColumns: "repeat(auto-fit, minmax(325px, 1fr))",

    [theme.breakpoints.up("sm")]: {},

    [theme.breakpoints.up("md")]: {},

    [theme.breakpoints.up("lg")]: {},
  },

  innerLayout: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, 1fr))",
    textAlign: "left",

    [theme.breakpoints.up("sm")]: {},

    [theme.breakpoints.up("md")]: {},

    [theme.breakpoints.up("lg")]: {},
  },

  responsive: {
    width: "100%",
    height: "auto",
  },

  itemTitle: {
    alignSelf: "center",
    justifySelf: "center",
  },
}));

export default function Airports() {
  const classes = useStyles({});
  return (
    <>
      <Grid className={classes.outerLayout} disableGutters>
        <Item>
          <Item className={classes.itemTitle} pb={2}>
            <LaLuxesScript fontsize={22}>
              <Box color="error.main">
                <Typography variant="h1">airports</Typography>
              </Box>
            </LaLuxesScript>
          </Item>

          <PTSerif fontsize={14}>
            <Paragraph>
              <Typography color="secondary" variant="h5">
                If you’re flying into town for the wedding, the Miami and Key
                West airports are around 75 miles from the venue, and there are
                plenty of fun and quirky places to visit along the way.
              </Typography>
            </Paragraph>
          </PTSerif>
        </Item>

        <Grid className={classes.innerLayout}>
          <Item>
            <Card>
              <Title>MIA</Title>
              <Subtitle>Miami International Airport</Subtitle>
              <Details> 1.5 hour drive to wedding venue </Details>
            </Card>
          </Item>
          <Item>
            <Card>
              <Title>EYW</Title>
              <Subtitle>Key West International Airport</Subtitle>
              <Details> 1.5 hour drive to wedding venue </Details>
            </Card>
          </Item>
          <Item>
            <Card>
              <Title>MCO</Title>
              <Subtitle>Orlando International Airport</Subtitle>
              <Details> 4.5 hour drive to wedding venue </Details>
            </Card>
          </Item>
        </Grid>
      </Grid>
    </>
  );
}

const Title = ({ children }) => {
  return (
    <PTSerif fontsize={20}>
      <Typography color="error" variant="h6">
        {children}
      </Typography>
    </PTSerif>
  );
};

const Subtitle = ({ children }) => {
  return (
    <PTSerif fontsize={16}>
      <Box color="warning.main">
        <Typography color="warning" variant="subtitle1">
          {children}
        </Typography>
      </Box>
    </PTSerif>
  );
};

const Details = ({ children }) => {
  return (
    <PTSerif fontsize={16}>
      <Flexbox>
        <Item p={2} px={1}>
          <Typography color="secondary" variant="body1">
            <FontAwesomeIcon icon={["far", "car"]} size="lg" />
          </Typography>
        </Item>
        <Item p={2} px={1}>
          <Typography color="secondary" variant="body1">
            {children}
          </Typography>
        </Item>
      </Flexbox>
    </PTSerif>
  );
};

const Card = ({ children }) => {
  return (
    <Paper elevation={2}>
      <Box p={2}>{children}</Box>
    </Paper>
  );
};
