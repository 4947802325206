import React from "react";

// Material UI
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
import {
  red,
  green,
  lightBlue,
  orange,
  cyan,
  purple,
} from "@material-ui/core/colors";
import "fontsource-pt-serif"; // Defaults to weight 400 with all styles included.

// fonts
import "../../../css/fonts.css";

function LaLuxesScript({ children, bold, fontsize = 18 }) {
  let theme = createMuiTheme({
    palette: {
      primary: {
        main: "#fff",
      },
      secondary: {
        main: "#909090",
      },
      error: {
        main: "#686767",
      },
      warning: {
        main: "#ce93d8",
      },
    },
    typography: {
      fontSize: fontsize,
      fontFamily: "La Luxes Script",
    },
  });

  // Typography font sizes in the theme responsive.
  theme = responsiveFontSizes(theme);

  return (
    <ThemeProvider theme={theme}>
      <span style={bold ? { textShadow: "1px 0 #FFFFFF, 0px 1px #FFFFFF, 1px 1px #FFFFFF" } : {}}>
        {children}
      </span>
    </ThemeProvider>
  );
}

//rgb(137,107,179,0.9);

function LaLuxesSerif({ children, bold, fontsize = 16 }) {
  let theme = createMuiTheme({
    palette: {
      primary: {
        main: "#fff",
      },
      secondary: {
        main: "#909090",
      },
      error: {
        main: "#686767",
      },
      warning: {
        main: "#ce93d8",
      },
      info: { main: orange[500] },
    },
    typography: {
      fontSize: fontsize,
      fontFamily: "La Luxes Serif",
    },
  });

  // Typography font sizes in the theme responsive.
  theme = responsiveFontSizes(theme);

  return (
    <ThemeProvider theme={theme}>
      <span style={bold ? { textShadow: "1px 0, 0px 1px, 1px 1px" } : {}}>
        {children}
      </span>
    </ThemeProvider>
  );
}

function PTSerif({ children, bold, fontsize = 16 }) {
  let theme = createMuiTheme({
    palette: {
      primary: {
        main: "#fff",
      },
      secondary: {
        main: "#909090",
      },
      error: {
        main: "#686767",
      },
      warning: {
        main: "#ce93d8",
      },
      info: { main: orange[300] },
    },
    typography: {
      fontSize: fontsize,
      fontFamily: "PT Serif",
      h5: { lineHeight: 1.6 },
    },
  });

  // Typography font sizes in the theme responsive.
  theme = responsiveFontSizes(theme);

  return (
    <ThemeProvider theme={theme}>
      <span style={bold ? { textShadow: "1px 0, 0px 1px, 1px 1px" } : {}}>
        {children}
      </span>
    </ThemeProvider>
  );
}

export { PTSerif, LaLuxesScript, LaLuxesSerif };
