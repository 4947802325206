import React, { useState, useEffect, useRef } from "react";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";

// App
import PhoneNumber from "../../../Inputs/PhoneNumber";
import { useSnackBar } from "../../../CustomizedSnackBars/CustomizedSnackBars";
import { db, functions } from "../../../../firebase/firebase";
import useFirestoreQuery from "../../../../firebase/useFirestoreQuery";
import Footer from "../../../Footer/Footer";
import {
  PTSerif,
  LaLuxesScript,
} from "../../../Typography/LaLuxesScript/LaLuxesScript";

// React Router
import { useHistory } from "react-router-dom";

// images
import flamingos from "../../../../images/rsvp/flamingos.png";
import flamingos_webp from "../../../../images/rsvp/flamingos.webp";
import string_lights from "../../../../images/rsvp/string_lights.png";
import string_lights_webp from "../../../../images/rsvp/string_lights.webp";
// import summer_escape_female_hat from "../../../../images/rsvp/summer_escape_female_hat.png";
// import summer_escape_female_hat_webp from "../../../../images/rsvp/summer_escape_female_hat.webp";
// import RsvpHeroWithMenu from "../../../Headers/RsvpHeroWithMenu/RsvpHeroWithMenu";

const useStyles = makeStyles((theme) => ({
  responsive: {
    width: "90%",
    height: "auto",
  },
  large: {
    width: "75%",
    height: "auto",
  },
  medium: {
    width: "50%",
    height: "auto",
  },
}));

export default function FindRsvp() {
  const classes = useStyles();

  const [mobile, setMobile] = useState("");
  const [show, setShow] = useState(true);

  const snackBar = useSnackBar();

  useEffect(() => {
    console.log("<FindRsvp />");
  });

  return (
    <div>
      <Box py={5} px={1}>
        <Container maxWidth="lg" disableGutters>
          <Paper elevation={1}>
            <Box p={2}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="stretch"
                spacing={2}
              >
                <Grid item>
                  <LaLuxesScript bold>
                    <Typography color="error" variant="h1">
                      RSVP
                    </Typography>
                  </LaLuxesScript>
                </Grid>

                <Grid item>
                  <PTSerif fontsize={16}>
                    <Typography color="secondary" variant="h5" paragraph>
                      We hope to be celebrating with you soon!
                    </Typography>
                  </PTSerif>

                  <picture>
                    <source type="image/webp" srcSet={string_lights_webp} />
                    <img
                      className={classes.responsive}
                      src={string_lights}
                      alt="string_lights"
                    />
                  </picture>
                  <br></br>
                  <br></br>
                  {/* <PTSerif fontsize={16}>
                  <Typography color="secondary" variant="body1" paragraph>
                    We hope to be celebrating with you soon!
                  </Typography>
                </PTSerif> */}
                  <br></br>
                  <br></br>

                  <picture>
                    <source type="image/webp" srcSet={flamingos_webp} />
                    <img
                      className={classes.medium}
                      src={flamingos}
                      alt="flamingos"
                    />
                  </picture>

                  <PTSerif fontsize={16}>
                    <Typography color="secondary" variant="h5" paragraph>
                      If you are responding for you and a guest (or family), you
                      can RSVP for the entire group.
                    </Typography>
                  </PTSerif>

                  <Divider variant="middle" />
                </Grid>

                {/* <Grid item>
                <picture>
                  <source
                    type="image/webp"
                    srcSet={summer_escape_female_hat_webp}
                  />
                  <img
                    className={classes.large}
                    src={summer_escape_female_hat}
                    alt="summer escape"
                  />
                </picture>
              </Grid> */}

                {show ? (
                  <EnterMobile
                    setShow={setShow}
                    mobile={mobile}
                    setMobile={setMobile}
                    snackBar={snackBar}
                  />
                ) : (
                  <EnterCode show={show} mobile={mobile} snackBar={snackBar} />
                )}
              </Grid>
            </Box>
          </Paper>
        </Container>
      </Box>
      <br></br>
      <Footer />
    </div>
  );
}

function EnterMobile({ setShow, mobile, setMobile, snackBar }) {
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const attempts = useRef(0);

  const persons = useFirestoreQuery(db.collection("persons"));

  console.log("pulse loading", isLoading);

  useEffect(() => {
    console.log("<EnterMobile />");
  });

  useEffect(() => {
    console.log("<EnterMobile loading/>");
    setIsLoading(persons.status === "loading");
  }, [persons]);

  useEffect(() => {
    const validMobileLength = 10;
    const mobileLength = mobile ? mobile.replace(/\D/g, "").length : 0;

    setIsDisabled(
      mobileLength === validMobileLength && persons.status !== "loading"
        ? false
        : true
    );
  }, [mobile, persons]);

  function handleFindRsvp() {
    const validMobileLength = 10;
    const mobileLength = mobile ? mobile.replace(/\D/g, "").length : 0;

    if (mobileLength !== validMobileLength) {
      return;
    }

    setIsLoading(true);

    const cleanMobile = `+1${mobile.replace(/\D/g, "")}`;
    const found = persons.data.find((person) => person.mobile === cleanMobile);

    if (found) {
      const formatMobileForTwilio = `+1${mobile.replace(/\D/g, "")}`;

      // call function that will send code
      var sendSecurityCode = functions.httpsCallable("sendSecurityCode");

      sendSecurityCode({ to: formatMobileForTwilio }).then((result) => {
        // reset loading
        setIsLoading(false);

        if (result.data.status) {
          setShow(false);

          // alert client of security code text message
          const message = ["We sent a 4-digit code to", `${mobile}`];
          const severity = "info";
          snackBar.set({ message, severity });
        }
      });
    } else {
      // reset loading
      setIsLoading(false);

      // reset show
      setShow(true);

      // increment failed attempts
      attempts.current = ++attempts.current;

      // alert client of failed attempt
      let message = [];
      let severity = "info";
      switch (attempts.current) {
        case 0:
        case 1:
          message = [
            "Hmm... We can't find an invitation associated with this number.",
            "Please make sure to enter your number in the following format: ",
            "(407) 123-4567",
          ];
          severity = "info";
          break;
        case 2:
          message = [
            "Oops..",
            "Still havig trouble? Please reach out to the couple and request this number be added.",
          ];
          severity = "warning";
          break;
        default:
          message = [
            "Still havig trouble? Please reach out to the couple and request this number be added.",
          ];
          severity = "error";
          break;
      }

      // prompt error message
      snackBar.set({ message, severity });
    }
  }

  return (
    <>
      <Grid item xs={12}>
        {/* <PTSerif fontsize={16}>
          <Typography color="secondary" variant="h5" paragraph>
            Enter your mobile number
          </Typography>
        </PTSerif> */}

        <PhoneNumber setValue={setMobile} />
      </Grid>

      <Grid item xs={12}>
        <Button
          fullWidth
          disabled={isDisabled}
          variant="contained"
          color="primary"
          onClick={isLoading ? null : handleFindRsvp}
        >
          {isLoading ? (
            <CircularProgress color="inherit" size={28} />
          ) : (
            "Find RSVP"
          )}
        </Button>
      </Grid>
    </>
  );
}

function EnterCode({ show, mobile, snackBar }) {
  const [code, setCode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  let history = useHistory();

  useEffect(() => {
    console.log("<EnterMobile />");
  });

  function handleContinue(e) {
    // send token
    // recieve url path for household
    //
    const validMobileLength = 10;
    const mobileLength = mobile ? mobile.replace(/\D/g, "").length : 0;

    if (mobileLength !== validMobileLength) {
      return;
    }

    const validCodeLength = 4;
    if (code.length !== validCodeLength) {
      // alert client of security code text message
      const message = ["Invalid Security Code."];
      const severity = "warning";
      snackBar.set({ message, severity });

      return;
    }

    setIsLoading(true);

    const formatMobileForTwilio = `+1${mobile.replace(/\D/g, "")}`;

    // call function that will send code
    var confirmSecurityCode = functions.httpsCallable("confirmSecurityCode");

    console.log("formatMobileForTwilio", formatMobileForTwilio, "code", code);

    confirmSecurityCode({ to: formatMobileForTwilio, code: code }).then(
      (result) => {
        // reset loading
        setIsLoading(false);

        // success
        if (result.data.status) {
          if (result.data.message === "valid") {
            // debug
            console.log("confirmSecurityCode: status", result.data.status);

            // navigate to attendees
            history.push(`/rsvp/${result.data.id}/attendees`);
          } else if (result.data.message === "invalid") {
            // alert client of security code text message
            const message = ["Invalid Security Code."];
            const severity = "warning";
            snackBar.set({ message, severity });
          }

          console.log("confirmSecurityCode", result.data);
        }
      }
    );
  }

  return (
    <>
      <Grid item xs={12}>
        <PTSerif fontsize={16}>
          <Typography color="secondary" variant="h5" paragraph>
            Enter your code
          </Typography>
        </PTSerif>

        <TextField
          autoFocus
          fullWidth
          variant="outlined"
          id="code"
          value={code}
          onChange={(event) => setCode(event.target.value)}
        />
      </Grid>

      <Grid item xs={12}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={isLoading ? null : handleContinue}
        >
          {isLoading ? (
            <CircularProgress color="inherit" size={28} />
          ) : (
            "Continue"
          )}
        </Button>
      </Grid>
    </>
  );
}
