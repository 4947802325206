import React, { useState, useEffect } from "react";

// React Router
import { useHistory, useParams } from "react-router-dom";

// Material UI

import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";

import Grid from "@material-ui/core/Grid";

import CircularProgress from "@material-ui/core/CircularProgress";

// App
import { db } from "../../../firebase/firebase";
import useFirestoreQuery from "../../../firebase/useFirestoreQuery";
import Footer from "../../Footer/Footer";
import LoadingCards from "../../loading/LoadingCards";

import {
  PTSerif,
  LaLuxesScript,
} from "../../Typography/LaLuxesScript/LaLuxesScript";

export default function Confirmation() {
  const [isLoading, setIsLoading] = useState(false);

  let { householdId } = useParams();
  let history = useHistory();

  // get persons of household
  const persons = useFirestoreQuery(
    householdId
      ? db.collection("persons").where("household", "==", householdId)
      : null
  );

  useEffect(() => {
    console.log("<Confirmation />");
  });

  function handleContinue(e) {
    setIsLoading(true);

    setTimeout(() => {
      history.push(`/rsvp/${householdId}/thanks`);
    }, 2 * 1000);
  }

  useEffect(() => {
    console.log("<Confirmation />");
  });

  if (persons.status === "loading") {
    return (
      <Box py={1} px={1}>
        <Container maxWidth="lg" disableGutters>
          <LoadingCards cards={1} waves={1} height={200} />
          <LoadingCards waves={1} height={140} />
        </Container>
      </Box>
    );
  }

  return (
    <div>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="stretch"
        spacing={2}
      >
        <Grid item xs={12}>
          <Box py={1} px={1}>
            <Container maxWidth="lg" disableGutters>
              <Paper elevation={1}>
                <Box p={2}>
                  <LaLuxesScript bold>
                    <Typography color="error" variant="h1">
                      Confirm
                    </Typography>
                  </LaLuxesScript>

                  <PTSerif fontsize={16}>
                    <Typography color="secondary" variant="subtitle1" paragraph>
                      Please review the information and select submit to send
                      your RSVP or Edit to modifiy your RSVP.
                    </Typography>
                  </PTSerif>
                </Box>
              </Paper>
            </Container>
          </Box>
        </Grid>

        <Grid
          container
          item
          direction="column"
          justify="center"
          alignItems="stretch"
          spacing={2}
        >
          <Persons persons={persons.data} id={householdId} />
        </Grid>

        <Grid item xs={12}>
          <Box mx={1}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={isLoading ? null : handleContinue}
            >
              {isLoading ? (
                <CircularProgress color="inherit" size={28} />
              ) : (
                "SUBMIT YOUR RSVP"
              )}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <br></br>
      <Footer />
    </div>
  );
}

function getOrderFromAge(age, mobile, attendance) {
  let order = 0;

  switch (age) {
    case "ADULT":
      break;
    case "CHILD":
      order = order + 2;
      break;
    case "INFANT":
      order = order + 3;
      break;
    default:
      order = order + 4;
  }

  order = mobile ? order : ++order;

  return attendance === "attending" ? order : order + 4;
}

const Persons = ({ persons, id }) => {
  useEffect(() => {
    console.log("<Persons />");
  });

  return (
    <>
      {persons &&
        persons.map((person) => {
          return (
            <Grid
              style={{
                order: getOrderFromAge(
                  person.age,
                  person.mobile,
                  person.wedding_invite_pt2.attendance
                ),
              }}
              key={person.id}
              item
              xs={12}
            >
              <Person person={person} id={id} />
            </Grid>
          );
        })}
    </>
  );
};

const Person = ({ person, id }) => {
  let history = useHistory();

  function handleEdit(e) {
    history.push(`/rsvp/${id}/attendees`);
  }

  return (
    <Box px={1}>
      <Container maxWidth="lg" disableGutters>
        <Paper elevation={1}>
          <Box p={1}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid
                container
                item
                xs={9}
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <Grid item>
                  <Box ml={1}>
                    <PTSerif fontsize={16}>
                      <Typography
                        style={{ marginBottom: 0 }}
                        color="error"
                        variant="h6"
                        paragraph
                      >
                        {person.full_name}
                      </Typography>
                    </PTSerif>
                  </Box>
                </Grid>

                <Grid item>
                  <Box ml={1}>
                    <PTSerif fontsize={16}>
                      <Typography color="secondary" variant="body1" paragraph>
                        {person.wedding_invite_pt2.attendance === "attending"
                          ? "Joyfully Accepts"
                          : person.wedding_invite_pt2.attendance === "declined"
                          ? "Regretfully Declines"
                          : "No Response"}
                      </Typography>
                    </PTSerif>
                  </Box>
                </Grid>
              </Grid>

              <Grid item xs={3}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleEdit}
                >
                  EDIT
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};
