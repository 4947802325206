import React from "react";

// App

import {
  PTSerif,
  LaLuxesScript,
  LaLuxesSerif,
} from "../../Typography/LaLuxesScript/LaLuxesScript";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Material UI
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

// images
import image1 from "../../../images/jpg/couple_bridge/couple_bridge_rough_mask_w_border.jpg";
import image2 from "../../../images/jpg/couple_beach_hat/couple_beach_hat_600w_opt.jpg";
import image3 from "../../../images/jpg/couple_lobster_trip/couple_lobster_trip_edit_750w_opt.jpg";
import sunrise from "../../../images/png/rotated_sunrise.png";
import sandcastle from "../../../images/png/sandcastle.png";
import where_vertical_text from "../../../images/png/where_text.png";

const CssGrid = withStyles({
  root: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    gridTemplateRows: "repeat(12, auto)",
  },
})(Container);

const Flexbox = withStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    alignItems: "center",
  },
})(Box);

const FlexboxColumn = withStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    alignItems: "center",
  },
})(Box);

const Item = withStyles({})(Box);

const useStyles = makeStyles((theme) => ({
  responsive: {
    width: "100%",
    height: "auto",
  },

  responsiveLg: {
    width: "90%",
    height: "auto",
  },

  offset: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(3),
  },

  rotate: {
    /* Abs positioning makes it not take up vert space */
    position: "absolute",
    top: "1rem",
    left: "0",

    /* Border is the new background */
    background: "none",

    /* Rotate from top left corner (not default) */
    transformOrigin: "0 0",
    transform: "rotate(90deg)",
  },

  rotateLeft: {
    /* Abs positioning makes it not take up vert space */

    position: "absolute",
    top: "8rem",
    // bottom: "0px",
    left: "-7.5rem",

    /* Border is the new background */
    background: "none",

    /* Rotate from top left corner (not default) */
    transformOrigin: "center",
    transform: "rotate(270deg)",
  },

  image: {
    width: "85%",
    height: "auto",

    [theme.breakpoints.up("md")]: {},
  },

  imageSunrise: {
    width: "4rem",
    height: "auto",
  },

  cssGrid: {
    [theme.breakpoints.up("sm")]: {},

    [theme.breakpoints.up("md")]: {},

    [theme.breakpoints.up("lg")]: {},
  },

  itemSunrise: {},

  itemTitle: {
    gridColumn: "1/-1",
    gridRow: "1",

    alignSelf: "center",
    justifySelf: "center",
  },

  itemTopImage: {
    gridColumn: "1/10",
    gridRow: "2",
  },

  itemTopVerticalText: {
    gridColumn: "13/-1",
    gridRow: "2",

    position: "relative",
  },

  itemDateText: {
    gridColumn: "1/-1",
    gridRow: "3",

    alignSelf: "center",
    justifySelf: "center",
  },

  itemImage2: {
    gridColumn: "1/7",
    gridRow: "4",
  },

  itemImage3: {
    gridColumn: "7/-1",
    gridRow: "4",
  },

  itemBotomVerticalText: {
    gridColumn: "1/4",
    gridRow: "5",
  },

  itemWhereText: {
    gridColumn: "4/-1",
    gridRow: "5",
  },
}));

export default function WeddingDetails() {
  const classes = useStyles({});

  return (
    <div id="id-wedding-details">
      <CssGrid className={classes.cssGrid}>
        <Item className={classes.itemTitle} pb={2}>
          <Flexbox className={classes.itemSunrise}>
            <img
              loading="lazy"
              className={classes.imageSunrise}
              src={sunrise}
              alt="sunrise"
            />

            <LaLuxesSerif fontsize={12}>
              <Typography
                color="error"
                variant="h2"
                style={{ marginLeft: "-1.5rem" }}
              >
                WEDD<span style={{ textDecoration: "underline" }}>ING</span>
              </Typography>
            </LaLuxesSerif>

            <Item pl={1} pt={6}>
              <LaLuxesScript fontsize={18}>
                <Typography color="error" variant="h1">
                  details
                </Typography>
              </LaLuxesScript>
            </Item>
          </Flexbox>
        </Item>

        <Item className={classes.itemTopImage}>
          <img
            loading="lazy"
            className={classes.responsive}
            src={image1}
            alt="couple bridge"
          />
        </Item>

        <Item className={classes.itemTopVerticalText}>
          <LaLuxesSerif fontsize={20}>
            <Typography
              color="secondary"
              variant="h1"
              className={classes.rotate}
            >
              WHEN
            </Typography>
          </LaLuxesSerif>
        </Item>

        <Item className={classes.itemDateText} pt={4}>
          <Flexbox>
            <LaLuxesSerif fontsize={18}>
              <Typography color="secondary" variant="h5">
                <FontAwesomeIcon
                  icon={["fal", "calendar"]}
                  size="lg"
                  fixedWidth
                />
              </Typography>
            </LaLuxesSerif>

            <PTSerif fontsize={14}>
              <Typography color="secondary" variant="h5">
                March 20, 2021 @ 4:00 PM
              </Typography>
            </PTSerif>
          </Flexbox>
        </Item>

        <Item className={classes.itemImage2} pt={4} pb={3} mr={0.5}>
          <img
            loading="lazy"
            className={classes.responsive}
            src={image3}
            alt="couple lobster trip"
          />
        </Item>

        <Item className={classes.itemImage3} pt={4} pb={3} ml={0.5}>
          <img
            loading="lazy"
            className={classes.responsive}
            src={image2}
            alt="couple big hat"
          />
        </Item>

        <Item className={classes.itemBotomVerticalText}>
          <Item>
            <img
              loading="lazy"
              className={classes.responsive}
              src={where_vertical_text}
              alt="where vertical text"
            />
          </Item>
        </Item>

        <Item className={classes.itemWhereText} pt={3}>
          <FlexboxColumn>
            <Item className={classes.itemTopImage} pb={2}>
              <img
                loading="lazy"
                className={classes.responsiveLg}
                src={sandcastle}
                alt="sandcastle"
              />
            </Item>

            <PTSerif fontsize={14}>
              <Typography color="secondary" variant="h5">
                Ocean Oasis Private Estate<br></br>
                75831 Overseas Highway <br></br>
                Islamorada, FL 33036
              </Typography>
            </PTSerif>
          </FlexboxColumn>
        </Item>
      </CssGrid>
    </div>
  );
}
