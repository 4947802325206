import React from 'react'

// App
import ButtonAppBar from "../components/Navigation/ButtonAppBar/ButtonAppBar";
import TravelHeroWithMenu from "../components/Headers/TravelHeroWithMenu/TravelHeroWithMenu";
import Airports from '../components/Section/Airports/Airports';
import ShuttleService from '../components/Section/ShuttleService/ShuttleService';
import Lodging from '../components/Section/Lodging/Lodging';
import Rentals from '../components/Section/Rentals/Rentals';
import Footer from "../components/Footer/Footer";

// Material UI
import Box from "@material-ui/core/Box";



export default function Travel() {
  return (
    <>
      <header>
        <Box mx={0}>
          <TravelHeroWithMenu />
        </Box>
      </header>
      <br></br>
      <br></br>
      <br></br>
      <Airports/>
      <br></br>
      <br></br>
      <ShuttleService/>
      <br></br>
      <br></br>
      <Lodging/>
      <br></br>
      <br></br>
      <Rentals />
      <br></br>
      <Footer />
      </>
  )
}
