import React from "react";

// Material UI
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

// Formik
import { Field } from "formik";

function CustomFormikTextField({ name, label, id, placeholder, icon }) {
  return (
    <Field name={name}>
      {(props) => {
        const { field } = props;
        return (
          <TextField
            fullWidth
            id={id || name}
            {...field}
            label={label || ""}
            placeholder={placeholder || ""}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              startAdornment: icon && (
                <InputAdornment position="start">{icon}</InputAdornment>
              ),
            }}
            variant="outlined"
          />
        );
      }}
    </Field>
  );
}

function CustomFormikTextAreaField({ name, label, id, placeholder, icon }) {
  return (
    <Field name={name}>
      {(props) => {
        const { field } = props;
        return (
          <TextField
            fullWidth
            id={id || name}
            multiline
            rows={4}
            {...field}
            label={label || ""}
            placeholder={placeholder || ""}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              startAdornment: icon && (
                <InputAdornment position="start">{icon}</InputAdornment>
              ),
            }}
            variant="outlined"
          />
        );
      }}
    </Field>
  );
}

export { CustomFormikTextField, CustomFormikTextAreaField };
