import React from "react";

// App
import {
  LaLuxesScript,
  LaLuxesSerif,
} from "../../Typography/LaLuxesScript/LaLuxesScript";
import FadeMenu from "../FadeMenu/FadeMenu";

// Font Awesome
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Material UI
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

// images
import image1 from "../../../images/travel/tropical_beach_resort/tropical_beach_resort_1515w.jpg";
import image1_webp from "../../../images/travel/tropical_beach_resort/tropical_beach_resort_1515w.webp";

const CssGrid = withStyles({
  root: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridTemplateRows: "repeat(3, 1fr)",
  },
})(Container);

const Item = withStyles({})(Box);

const useStyles = makeStyles((theme) => ({
  image: {
    width: "100%",
    height: "auto",
    justifyItems: "center",
    alignItems: "center",

    [theme.breakpoints.up("md")]: {},
  },
  cssGrid: {
    [theme.breakpoints.up("sm")]: {},

    [theme.breakpoints.up("md")]: {},

    [theme.breakpoints.up("lg")]: {},
  },
  imageItem: {
    gridColumn: "1/-1",
    gridRow: "1/-1",
  },
  hamburger: {
    gridColumn: "1/-1",
    gridRow: "1",
    justifySelf: "end",
  },
  title: {
    gridColumn: "1/-1",
    gridRow: "1/-1",

    alignSelf: "center",
    justifySelf: "center",
  },
  subtitle: {
    gridColumn: "1/-1",
    gridRow: "3",
  },
  menu: {
    marginTop: theme.spacing(-5),
    "& > *": {},
  },
}));

export default function HeroWithMenu() {
  const classes = useStyles({});

  return (
    <>
      <CssGrid maxWidth={false} disableGutters>
        <Item className={classes.imageItem}>
          <picture>
            <source type="image/webp" srcSet={image1_webp} />
            <img
              className={classes.image}
              src={image1}
              alt="dock with ocean view"
            />
          </picture>
        </Item>

        <Item className={classes.hamburger}>
          <FadeMenu />
        </Item>

        <Item className={classes.title}>
          <LaLuxesScript bold>
            <Typography color="primary" variant="h1">
              Travel
            </Typography>
          </LaLuxesScript>
        </Item>

        <Item className={classes.subtitle}>
          <Box bgcolor="#D1B6CD">
            <LaLuxesSerif bold>
              <Typography
                color="primary"
                styles={{}}
                variant="subtitle1"
              ></Typography>
            </LaLuxesSerif>
          </Box>
        </Item>
      </CssGrid>

      <div className={classes.menu}>
        {/* <ThemedFabutton
          title={"Welcome"}
          color="red"
          herf="#id-welcome"
          shade={200}
        >
          <FontAwesomeIcon icon={["far", "heart"]} size="2x" />
        </ThemedFabutton>

        <ThemedFabutton
          title={"Details"}
          color="blue"
          herf="#id-details"
          shade={200}
        >
          <FontAwesomeIcon icon={["far", "calendar-day"]} size="2x" />
        </ThemedFabutton>

        <ThemedFabutton
          title={"Contact"}
          color="orange"
          herf="#id-contact"
          shade={200}
        >
          <FontAwesomeIcon icon={["far", "comment-dots"]} size="2x" />
        </ThemedFabutton>

        <ThemedFabutton
          title={"Registry"}
          color="purple"
          herf="#id-registry"
          shade={200}
        >
          <FontAwesomeIcon icon={["fal", "gift"]} size="2x" />
        </ThemedFabutton> */}
      </div>
    </>
  );
}
