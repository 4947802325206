import React from "react";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import Grid from "@material-ui/core/Grid";

// Font Awesome
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// App
import Footer from "../../Footer/Footer";

// images
import stamp from "../../../images/rsvp/stamp_coca_cola_500w.png";
import stamp_webp from "../../../images/rsvp/stamp_coca_cola_500w.webp";

import {
  PTSerif,
  LaLuxesScript,
  LaLuxesSerif,
} from "../../Typography/LaLuxesScript/LaLuxesScript";

const useStyles = makeStyles((theme) => ({
  responsive: {
    width: "90%",
    height: "auto",
  },
  large: {
    width: "75%",
    height: "auto",
  },
  medium: {
    width: "50%",
    height: "auto",
  },
}));

export default function Thanks() {
  const classes = useStyles();

  return (
    <div>
      <Box py={5} px={1}>
        <Container maxWidth="lg" disableGutters>
          <Paper elevation={1}>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={4}>
                <picture>
                  <source type="image/webp" srcSet={stamp_webp} />
                  <img className={classes.responsive} src={stamp} alt="stamp" />
                </picture>
              </Grid>
            </Grid>

            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12}>
                <LaLuxesScript>
                  <Typography color="error" variant="h3">
                    Your RSVP has been sent!
                  </Typography>
                </LaLuxesScript>
              </Grid>

              <Grid item xs={12}>
                <Box mt={3} mb={2} mx={2}>
                  <PTSerif fontsize={16}>
                    <Typography color="secondary" variant="h6" paragraph>
                      If you need to make a change to your RSVP just visit the
                      RSVP section of our website.
                    </Typography>
                  </PTSerif>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </Box>
      <br></br>
      <Footer />
    </div>
  );
}
