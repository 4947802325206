import React from "react";

// App
import {
  PTSerif,
  LaLuxesScript,
  LaLuxesSerif,
} from "../../Typography/LaLuxesScript/LaLuxesScript";

// Font Awesome
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Material UI
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import useMediaQuery from "@material-ui/core/useMediaQuery";

// images
import background from "../../../images/png/rough_cream_background_opt.png";
import background_webp from "../../../images/png/rough_cream_background.webp";
import image1 from "../../../images/jpg/wall_crawl_triple_kiss_2000x1125.jpg";
import image2 from "../../../images/jpg/wall_crawl_ducks_2500x1668.jpg";
import image2webp from "../../../images/jpg/wall_crawl_ducks_2500x1668.webp";
import botanicals from "../../../images/png/botanicals_27_opt.png";
import starPulse from "../../../images/png/star_pulse.png";

const CssGrid = withStyles({
  root: {
    display: "grid",
    gridTemplateColumns: "repeat(6, 1fr)",
    gridTemplateRows: "repeat(12, auto)",
  },
})(Container);

const Flexbox = withStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    alignItems: "center",
  },
})(Box);

const Item = withStyles({})(Box);

const useStyles = makeStyles((theme) => ({
  responsive: {
    width: "100%",
    height: "auto",
  },
  image: {
    width: "85%",
    height: "auto",

    [theme.breakpoints.up("md")]: {},
  },
  cssGrid: {
    [theme.breakpoints.up("sm")]: {},

    [theme.breakpoints.up("md")]: {},

    [theme.breakpoints.up("lg")]: {},
  },

  itemTopBackgroundImage: {
    gridColumn: "1/-1",
    gridRow: "1/-1",

    alignSelf: "start",
    justifySelf: "center",
  },

  itemBottomBackgroundImage: {
    gridColumn: "1/-1",
    gridRow: "1/-1",

    alignSelf: "end",
    justifySelf: "center",
  },

  itemTitle: {
    gridColumn: "1/-1",
    gridRow: "2",

    alignSelf: "center",
    justifySelf: "center",
  },

  itemBotanicalImage: {
    gridColumn: "4/-1",
    gridRow: "3",
  },

  itemTopImage: {
    gridColumn: "1/6",
    gridRow: "3",
  },

  itemBody: {
    gridColumn: "1/-1",
    gridRow: "4",

    textAlign: "left",

    alignSelf: "center",
    justifySelf: "center",
  },

  itemStarPulseImage: {
    gridColumn: "1/4",
    gridRow: "5",
  },

  itemBottomImage: {
    gridColumn: "2/-1",
    gridRow: "5",
  },
}));

export default function WelcomeEveryone() {
  const classes = useStyles({});
  const atMd = useMediaQuery("(min-width:960px)");

  return (
    <>
      <CssGrid maxWidth={false} disableGutters>
        <Item className={classes.itemTopBackgroundImage}>
          <picture id="id-welcome-everyone">
            <source type="image/webp" srcSet={background_webp} />
            <img
              className={classes.image}
              src={background}
              alt="top sand background"
            />
          </picture>
        </Item>

        <Item className={classes.itemBottomBackgroundImage}>
          <picture>
            <source type="image/webp" srcSet={background_webp} />
            <img
              className={classes.image}
              src={background}
              alt="bottom sand background"
            />
          </picture>
        </Item>

        <Item className={classes.itemBotanicalImage} pt={2}>
          <img
            className={classes.responsive}
            src={botanicals}
            alt="botanicals"
          />
        </Item>

        <Item className={classes.itemTitle}>
          <Flexbox className={classes.itemSunrise}>
            <LaLuxesSerif fontsize={12}>
              <Typography color="error" variant="h2">
                WELC<span style={{ textDecoration: "underline" }}>OME</span>
              </Typography>
            </LaLuxesSerif>

            <Item pt={4} pl={1}>
              <LaLuxesScript fontsize={18}>
                <Typography color="error" variant="h1">
                  Everyone
                </Typography>
              </LaLuxesScript>
            </Item>
          </Flexbox>
        </Item>

        <Item className={classes.itemTopImage} pt={3}>
          <img
            className={classes.image}
            src={image1}
            alt="triple kiss with besssie"
          />
        </Item>

        <Item className={classes.itemBody} mt={-7} p={2}>
          <PTSerif fontsize={16}>
            <Typography color="secondary" variant="h5" paragraph>
              We can’t tell you how excited we are to get to spend time with all
              of you during our wedding festivities.
            </Typography>

            <Typography color="secondary" variant="h5" paragraph>
              To make things easier and a little more fun for you, we’ve created
              this interactive website with all the important stuff, including
              the event information, travel information, interactive maps, where
              to stay, things to do, and FAQs, so you can access all of the
              wedding information in one place.
            </Typography>

            <Typography color="secondary" variant="h5" paragraph>
              We will be posting more information on the website as the wedding
              gets closer, so be sure to check back.
            </Typography>
          </PTSerif>
        </Item>

        <Item className={classes.itemStarPulseImage} pt={10} pr={5}>
          <img
            loading="lazy"
            className={classes.responsive}
            src={starPulse}
            alt="star pulse"
          />
        </Item>

        <Item className={classes.itemBottomImage} pt={3}>
          <picture>
            <source type="image/webp" srcSet={image2webp} />
            <img
              loading="lazy"
              src={image2}
              alt="couple with ducks and bessie"
              className={classes.image}
            />
          </picture>
        </Item>
      </CssGrid>
    </>
  );
}
