import React from "react";

// App
import { LaLuxesSerif } from "../../Typography/LaLuxesScript/LaLuxesScript";

// Material UI
import { makeStyles, withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

// images
import image1 from "../../../images/jpg/islamorada_map/islamorada_map_clean_1038w_662h.jpg";
import image2 from "../../../images/png/water_edge.png";

const CssGrid = withStyles({
  root: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridTemplateRows: "repeat(3, 1fr)",
  },
})(Container);

const Item = withStyles({})(Box);

const useStyles = makeStyles((theme) => ({
  image: {
    width: "90%",
    height: "auto",

    [theme.breakpoints.up("md")]: {
      width: "80%",
    },
  },
  responsive: {
    width: "100%",
    height: "auto",
    [theme.breakpoints.up("md")]: {},
  },

  cssGrid: {
    [theme.breakpoints.up("sm")]: {},

    [theme.breakpoints.up("md")]: {},

    [theme.breakpoints.up("lg")]: {},
  },
  imageItem: {
    gridColumn: "1/-1",
    gridRow: "1/-1",
  },
  topLeftItem: {
    gridColumn: "1/-1",
    gridRow: "1",

    alignSelf: "start",
    justifySelf: "start",
  },
  bottomRightItem: {
    gridColumn: "1/-1",
    gridRow: "3",

    alignSelf: "end",
    justifySelf: "end",
  },
  waveItem: {
    gridColumn: "1/-1",
    gridRow: "4",
  },
}));

export default function IslamoradaBanner() {
  const classes = useStyles({});

  const atMd = useMediaQuery("(min-width:960px)");

  return (
    <>
      <CssGrid disableGutters>
        <Item className={classes.imageItem}>
          <img
            className={classes.image}
            src={image1}
            alt="watercolor map of islamorada"
          />
        </Item>

        <Item className={classes.topLeftItem} px={1}>
          <LaLuxesSerif>
            <Typography color="secondary" variant={atMd ? "h5" : "h6"}>
              Islamorada, FL
            </Typography>
          </LaLuxesSerif>
        </Item>

        <Item className={classes.bottomRightItem} px={1}>
          <LaLuxesSerif>
            <Typography color="secondary" variant={atMd ? "h5" : "h6"}>
              March 20, 2021
            </Typography>
          </LaLuxesSerif>
        </Item>

        <Item className={classes.waveItem} pt={10}>
          <img className={classes.responsive} src={image2} alt="water edge" />
        </Item>
      </CssGrid>
    </>
  );
}
