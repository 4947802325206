import React from "react";

// Material UI
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

// App
import HeroWithMenu from "../components/Headers/HeroWithMenu/HeroWithMenu";
import IslamoradaBanner from "../components/Banner/IslamoradaBanner/IslamoradaBanner";
import WelcomeEveryone from "../components/Section/WelcomeEveryone/WelcomeEveryone";
import FlowerBanner from "../components/Section/FlowerBanner/FlowerBanner";
import WeddingDetails from "../components/Section/WeddingDetails/WeddingDetails";
import ContactUs from "../components/Section/ContactUs/ContactUs";
import Registry from "../components/Section/Registry/Registry";
import Footer from "../components/Footer/Footer";
import {
  PTSerif,
  LaLuxesScript,
  LaLuxesSerif,
} from "../components/Typography/LaLuxesScript/LaLuxesScript";

export default function LandingPage() {
  return (
    <>
      <header>
        <Box mx={0}>
          <HeroWithMenu />
        </Box>
      </header>
      <br></br>
      <br></br>
      <IslamoradaBanner />
      <br></br>
      <br></br>
      <br></br>
      <WelcomeEveryone />
      <FlowerBanner />
      <WeddingDetails />
      <br></br>
      <br></br>
      <br></br>
      <ContactUs />
      <br></br>
      <br></br>
      <br></br>
      <Registry />
      <br></br>
      <PTSerif fontsize={16}>
        <Box p={2}>
          <Typography color="secondary" variant="h6" paragraph>
            Let us know if you have any questions or if we can assist in
            rescheduling efforts in any way.
          </Typography>
        </Box>
      </PTSerif>
 
      <Footer />
    </>
  );
}
