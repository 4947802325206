import { useState, useEffect } from "react";

// App
import useMemoCompare from "./useMemoCompare";

export default function useUpdateDocRef(query, allowEmptyFields = false) {
  const [doc, setDoc] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false);
  const [error, setError] = useState(false);

  // Get cached Firestore query object with useMemoCompare (https://usehooks.com/useMemoCompare)
  // Needed because firestore.collection("profiles").doc(uid) will always being a new object reference
  // causing effect to run -> state change -> rerender -> effect runs -> etc ...
  // This is nicer than requiring hook consumer to always memoize query with useMemo.
  const queryCached = useMemoCompare(query, (prevQuery) => {
    // Use built-in Firestore isEqual method to determine if "equal"
    return prevQuery && query && query.isEqual(prevQuery);
  });

  useEffect(() => {
    // Return early if query is falsy and reset to "idle" status in case
    // we're coming from "success" or "error" status due to query change.
    if (!queryCached) {
      // dispatch({ type: "idle" });
      return;
    }

    // prevent updates on null
    if (!doc) {
      return;
    }

    // clean values
    let values = { ...doc };
    delete values.id;

    if (allowEmptyFields) {
      // Remove blank attributes from a JavaScript Object
      Object.keys(values).forEach(
        (key) => values[key] == null && delete values[key]
      );
    } else {
      // Remove blank/empty-string attributes from a JavaScript Object
      Object.keys(values).forEach(
        (key) =>
          (values[key] == null || values[key] === "") && delete values[key]
      );
    }

    // Upload values to firebase
    return queryCached
      .update(values)
      .then(function () {
        console.log("Document updated successfully!");
        setError(false);
        setIsUpdated(true);
      })
      .catch(function (err) {
        console.error("Error updating document.", err);
        setError(error);
      });
  }, [queryCached, doc, error, allowEmptyFields]);

  return [setDoc, isUpdated, error];
}
