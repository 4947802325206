import React from "react";

// App
import {
  PTSerif,
  LaLuxesScript,
} from "../Typography/LaLuxesScript/LaLuxesScript";

// // Font Awesome
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Material UI
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";

// images
import bessie_logo from "../../images/png/bessie_logo_w_flowers.png";
import bessie_logo_webp from "../../images/png/bessie_logo_w_flowers.webp";

const CssGrid = withStyles({
  root: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    gridTemplateRows: "repeat(12, auto)",
  },
})(Container);

const Item = withStyles({})(Box);

const useStyles = makeStyles((theme) => ({
  responsive: {
    width: "60%",
    height: "auto",
  },
  cssGrid: {
    [theme.breakpoints.up("sm")]: {},

    [theme.breakpoints.up("md")]: {},

    [theme.breakpoints.up("lg")]: {},
  },

  itemTopBackgroundImage: {
    gridColumn: "1/-1",
    gridRow: "1/-1",

    alignSelf: "center",
    justifySelf: "center",
  },

  itemTitle: {
    gridColumn: "1/-1",
    gridRow: "1",

    alignSelf: "center",
    justifySelf: "center",
  },

  itemBody: {
    gridColumn: "1/-1",
    gridRow: "2",
  },

  itemNumber: {
    gridColumn: "1/-1",
    gridRow: "3",
  },
}));

export default function Footer() {
  const classes = useStyles({});

  return (
    <>
      <CssGrid className={classes.cssGrid} maxWidth="lg" disableGutters>
        <Item className={classes.itemTopBackgroundImage}>
          <br></br>
          <Divider variant="middle" />
          <br></br>
          <picture>
            <source type="image/webp" srcSet={bessie_logo_webp} />
            <img
              loading="lazy"
              className={classes.responsive}
              src={bessie_logo}
              alt="bessie logo"
            />
          </picture>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="baseline"
          >
            <Grid item>
              <PTSerif fontsize={16}>
                <Typography color="secondary" variant="body1" paragraph>
                  <Box pr={1}>&#169;2020</Box>
                </Typography>
              </PTSerif>
            </Grid>
            <Grid item>
              <LaLuxesScript fontsize={12}>
                <Box color="error.main">
                  <Typography variant="h3">
                    Made with love and a little whiskey.
                  </Typography>
                </Box>
              </LaLuxesScript>
            </Grid>
          </Grid>
        </Item>
      </CssGrid>
    </>
  );
}
