import React, { useState } from "react";
import PropTypes from "prop-types";

// React Text Mask
import MaskedInput from "react-text-mask";

// Material UI
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      guide={false}
      mask={[
        "(",
        /[1-9]/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
      showMask={false}
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

export default function PhoneNumber({ setValue }) {
  const [values, setValues] = useState("");

  const handleChange = (event) => {
    setValues(event.target.value);
    setValue(event.target.value);
  };

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel htmlFor="formatted-text-mask-input">
        Enter your mobile number
      </InputLabel>
      <OutlinedInput
        defaultValue=""
        value={values}
        onChange={handleChange}
        placeholder="407 123 4567"
        name="textmask"
        id="formatted-text-mask-input"
        inputComponent={TextMaskCustom}
        labelWidth={200}
      />
    </FormControl>
  );
}
