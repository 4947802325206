import React from "react";

// App
import {
  PTSerif,
  LaLuxesScript,
} from "../../Typography/LaLuxesScript/LaLuxesScript";
import IconButton from "../../Buttons/IconButton";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Material UI
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import SingleLineGridList from "./SingleLineGridList";
import { blueGrey } from "@material-ui/core/colors";

// images
import image1 from "../../../images/travel/amara_cay_hotel/amara_cay_building_500w.jpg";
import image2 from "../../../images/travel/amara_cay_hotel/amara_cay_firepit_500w.jpg";
import image3 from "../../../images/travel/amara_cay_hotel/amara_cay_pool_500w.jpg";
import image4 from "../../../images/travel/amara_cay_hotel/amara_cay_pool_plus_500w.jpg";
import image5 from "../../../images/travel/amara_cay_hotel/amara_cay_tiki_bar_500w.jpg";

import imageS1 from "../../../images/travel/la_siesta_resort/la_siesta_hotel_500w.jpg";
import imageS2 from "../../../images/travel/la_siesta_resort/la_siesta_hotel_beach_500w.jpg";
import imageS3 from "../../../images/travel/la_siesta_resort/la_siesta_pool_plus_500w.jpg";
import imageS4 from "../../../images/travel/la_siesta_resort/la_siesta_room_double_500w.jpg";
import imageS5 from "../../../images/travel/la_siesta_resort/la_siesta_room_king_500w.jpg";

import imageI1 from "../../../images/travel/islander_oceanside_hotel/islander_resort_oceanside_courtyward_500w.jpg";
import imageI2 from "../../../images/travel/islander_oceanside_hotel/islander_resort_oceanside_slash_pad_500w.jpg";
import imageI3 from "../../../images/travel/islander_oceanside_hotel/islander_resort_oceanside_pool_500w.jpg";
import imageI4 from "../../../images/travel/islander_oceanside_hotel/islander_resort_oceanside_beach_500w.jpg";
import imageI5 from "../../../images/travel/islander_oceanside_hotel/islander_resort_oceanside_room_500w.jpg";

import imageB1 from "../../../images/travel/breezy_palms_resort/breezy_palms_beach_cottage_500w.jpg";
import imageB2 from "../../../images/travel/breezy_palms_resort/breezy_palms_pool_500w.jpg";
import imageB3 from "../../../images/travel/breezy_palms_resort/breezy_palms_poolside_appartments_500w.jpg";
import imageB4 from "../../../images/travel/breezy_palms_resort/breezy_palms_resort_beachfront_500w.jpg";
import imageB5 from "../../../images/travel/breezy_palms_resort/breezy_palms_small_efficiency_500w.jpg";

const amara_cay = [
  {
    img: image1,
    title: "amara cay building",
  },
  {
    img: image4,
    title: "amara cay pool",
  },

  {
    img: image2,
    title: "amara cay firepit",
  },
  {
    img: image3,
    title: "amara cay pool",
  },

  {
    img: image5,
    title: "amara cay tiki bar",
  },
];

const la_siesta = [
  {
    img: imageS1,
    title: "amara cay building",
  },
  {
    img: imageS3,
    title: "amara cay pool",
  },

  {
    img: imageS2,
    title: "amara cay firepit",
  },

  {
    img: imageS4,
    title: "amara cay pool",
  },
  {
    img: imageS5,
    title: "amara cay bar",
  },
];

const islander = [
  {
    img: imageI1,
    title: "amara cay building",
  },
  {
    img: imageI2,
    title: "amara cay pool",
  },

  {
    img: imageI3,
    title: "amara cay firepit",
  },

  {
    img: imageI4,
    title: "amara cay pool",
  },
  {
    img: imageI5,
    title: "amara cay bar",
  },
];

const breezy = [
  {
    img: imageB4,
    title: "amara cay pool",
  },
  {
    img: imageB2,
    title: "amara cay pool",
  },

  {
    img: imageB3,
    title: "amara cay firepit",
  },

  {
    img: imageB1,
    title: "amara cay building",
  },

  {
    img: imageB5,
    title: "amara cay bar",
  },
];

const Grid = withStyles({
  root: {
    display: "grid",
    rowGap: "20px",
  },
})(Container);

const Flexbox = withStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    alignItems: "center",
  },
})(Box);

const Item = withStyles({})(Box);

const useStyles = makeStyles((theme) => ({
  outerLayout: {
    gridTemplateColumns: "repeat(auto-fit, minmax(325px, 1fr))",

    [theme.breakpoints.up("sm")]: {},

    [theme.breakpoints.up("md")]: {},

    [theme.breakpoints.up("lg")]: {},
  },

  innerLayout: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, 1fr))",
    textAlign: "left",

    [theme.breakpoints.up("sm")]: {},

    [theme.breakpoints.up("md")]: {},

    [theme.breakpoints.up("lg")]: {},
  },

  responsive: {
    width: "100%",
    height: "auto",
  },

  itemTitle: {
    alignSelf: "center",
    justifySelf: "center",
  },

  itemBody: {
    textAlign: "left",
  },
  root: {
    color: theme.palette.getContrastText(blueGrey[50]),
    backgroundColor: blueGrey[50],
  },
}));

export default function Lodging() {
  const classes = useStyles({});

  return (
    <>
      <Grid className={classes.outerLayout} disableGutters>
        <Item className={classes.itemTitle} pb={2}>
          <LaLuxesScript fontsize={18}>
            <Typography color="error" variant="h1">
              Accomodations
            </Typography>
          </LaLuxesScript>
        </Item>

        <Item className={classes.itemBody} px={2} py={1}>
          <PTSerif fontsize={20}>
            <Typography color="secondary" variant="subtitle1" paragraph>
              We reserved a limited number of rooms at a special rate at the
              Amara Cay Resort. These rates are available by accessing the
              booking link below or by calling 1-855-335-1073 and referencing
              the O'Brien/Ayala Wedding.
            </Typography>
            <Typography color="secondary" variant="subtitle1" paragraph>
              The rooms are blocked off for Friday, March 19, 2020 through
              Sunday March 21, 2020, but the discounted rates are available for
              3 days before and 3 days after the dates blocked off.
            </Typography>
          </PTSerif>
        </Item>

        <Card>
          <Title>Amara Cay Resort</Title>
          <Booking href="https://be.synxis.com/?adult=1&arrive=2021-03-19&chain=52&child=0&currency=USD&depart=2021-03-22&group=210318OBRI&hotel=77821&level=hotel&locale=en-US&rooms=1"></Booking>
          <Body>
            <Box mb={1}>
              <FontAwesomeIcon
                icon={["far", "phone-alt"]}
                size="sm"
                fixedWidth
              />{" "}
              (855) 335-1073
            </Box>
            We blocked off rooms at Amara Cay, which is the closest recommended
            hotel to the venue. The resort has unbeatable views with a pool,
            beach, tiki bar, and outdoor restaurant. It’s family friendly and
            has shuttles running within a 4-mile radius of the hotel.
          </Body>
          <Table container justify="flex-start" spacing={1}>
            <Feature icon="car" text="5 minute drive to venue" />

            <Feature icon="sack-dollar" text="Discount rate available" />

            <Feature icon="cocktail" text="Tiki Bar" />

            <Feature icon="bus" text="Shuttle transportation" />

            <Feature icon="dog" text="Pet Friendly (less than 30lbs)" />

            <Feature icon="swimmer" text="Pool" />
          </Table>
          <br></br>
          <SingleLineGridList tileData={amara_cay} />
        </Card>

        <Card>
          <Title>La Siesta Resort &amp; Marina</Title>
          <Booking href="https://www.lasiestaresort.com"></Booking>
          <Body>
            La Siesta Resort &amp; Marina, Amara Cay’s sister property, is 0.3
            miles north of Amara Cay and has the same unbeatable views with
            rooms and suites that accommodate larger groups of people. We did
            not block rooms off at this property, but the resort offers Florida
            Resident, Senior, AAA, Local Hero, Government, and various other
            discounts. It also runs shuttles within a 4-mile radius of the
            hotel, and both hotels share amenities.
          </Body>
          <Table container justify="flex-start" spacing={1}>
            <Feature icon="car" text="6 minute drive to venue" />

            <Feature icon="sack-dollar" text="Discount rate available" />

            <Feature icon="cocktail" text="Tiki Bar" />

            <Feature icon="bus" text="Shuttle transportation" />

            <Feature icon="dog" text="Pet Friendly (less than 30lbs)" />

            <Feature icon="swimmer" text="Pool" />
          </Table>
          <br></br>
          <SingleLineGridList tileData={la_siesta} />
        </Card>

        <Card>
          <Title> Islander Resort - Oceanside</Title>
          <Booking href="https://www.islanderfloridakeys.com"></Booking>
          <Body>
            Islander Resort is a little farther north of the venue, but we
            recommend it as well as it is very kid-friendly with a large pool,
            splash pad, beach, and restaurants on the property. The Islander
            also offers AARP and AAA discounts.
          </Body>
          <Table container justify="flex-start" spacing={1}>
            <Feature icon="car" text="9 minute drive to venue" />

            <Feature icon="baby" text="Children Splash Pad" />

            <Feature icon="cocktail" text="Poolside Bar" />

            <Feature icon="bus" text="Shuttle transportation" />

            <Feature icon="dog" text="Pet Friendly" />

            <Feature icon="swimmer" text="Pool" />
          </Table>
          <br></br>
          <SingleLineGridList tileData={islander} />
        </Card>

        <Card>
          <Title> Breezy Palms Resort</Title>
          <Booking href="https://www.breezypalms.com"></Booking>
          <Body>
            Breezy Palms Resort is a more budget-friendly hotel located right
            next to Amara Cay Resort, so it also has the same unbeatable views.
            It’s also convenient to the venue and offers various types of rooms,
            cottages, and bungalows. The resort has a pool, beach, barbecue
            area, volleyball court, shuffleboard courts, and boat slips.
          </Body>
          <Table container justify="flex-start" spacing={1}>
            <Feature icon="car" text="5 minute drive to venue" />

            <Feature icon="sack-dollar" text="Discount rate available" />

            <Feature icon="cocktail" text="Tiki Bar" />

            <Feature icon="bus" text="Shuttle transportation" />

            <Feature icon="dog" text="Pet Friendly (less than 30lbs)" />

            <Feature icon="swimmer" text="Pool" />
          </Table>
          <br></br>
          <SingleLineGridList tileData={breezy} />
        </Card>
      </Grid>
    </>
  );
}

const Feature = ({ icon, text }) => {
  return (
    <Table item xs={12}>
      <PTSerif fontsize={16}>
        <Flexbox>
          <Item px={1}>
            <Box color="info.main">
              <Typography variant="body1">
                <FontAwesomeIcon
                  icon={["far", `${icon}`]}
                  size="lg"
                  fixedWidth
                />
              </Typography>
            </Box>
          </Item>
          <Item px={1}>
            <Typography color="secondary" variant="body1">
              {text}
            </Typography>
          </Item>
        </Flexbox>
      </PTSerif>
    </Table>
  );
};

const Body = ({ children }) => {
  return (
    <PTSerif fontsize={20}>
      <Flexbox>
        <Item p={2} px={1}>
          <Typography color="secondary" variant="subtitle1">
            {children}
          </Typography>
        </Item>
      </Flexbox>
    </PTSerif>
  );
};

const Booking = ({ children, href }) => {
  return (
    <Button href={href}>
      <IconButton>
        Make a reservation
        <Box pl={1}>
          <FontAwesomeIcon icon={["fal", "paper-plane"]} size="lg" />
        </Box>
      </IconButton>
    </Button>
  );
};

const Title = ({ children }) => {
  return (
    <PTSerif fontsize={18}>
      <Flexbox>
        {/* <Item p={2} px={1}>
          <Typography color="error" variant="h6">
            <FontAwesomeIcon icon={["fal", "bed-alt"]} size="lg" />
          </Typography>
        </Item> */}
        <Item px={1}>
          <Typography color="error" variant="h4">
            {children}
          </Typography>
        </Item>
      </Flexbox>
    </PTSerif>
  );
};

const Card = ({ children }) => {
  return (
    <>
      <Divider variant="middle" />
      <Box p={2} textAlign="left">
        {children}
      </Box>
    </>
  );
};
