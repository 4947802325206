import React from "react";

// Material UI
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";

// images
import goldenTurtle from "../../../images/png/golden_turtle_1200w.png";
import goldenTurtle_webp from "../../../images/png/golden_turtle_1200w.webp";

const CssGrid = withStyles({
  root: {
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
  },
})(Container);

const Item = withStyles({})(Box);

const useStyles = makeStyles((theme) => ({
  image: {
    width: "80%",
    height: "auto",

    [theme.breakpoints.up("md")]: {
      width: "80%",
    },
  },
  imageItem: {
    gridColumn: "1",
  },
}));

export default function FlowerBanner() {
  const classes = useStyles({});

  return (
    <CssGrid maxWidth="sm">
      <Item className={classes.imageItem} pl={7}>
        <picture>
          <source type="image/webp" srcSet={goldenTurtle_webp} />
          <img
            loading="lazy"
            className={classes.image}
            src={goldenTurtle}
            alt="watercolor map of islamorada"
          />
        </picture>
      </Item>
    </CssGrid>
  );
}
