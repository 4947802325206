import React from "react";

// Material UI
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import {
  red,
  green,
  lightBlue,
  orange,
  cyan,
  purple,
} from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import StarBorderIcon from "@material-ui/icons/StarBorder";

// Link to color picker
// https://material-ui.com/customization/color/

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    color: "white",
  },
}));

export default function IconButton({
  children,
  color = "orange",
  herf,
  shade = 500,
}) {
  const dict = {
    red: red[shade],
    green: green[shade],
    blue: lightBlue[shade],
    orange: orange[shade],
    cyan: cyan[shade],
    purple: purple[shade],
  };

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: dict[color],
      },
    },
  });

  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>

        <Button href={herf} variant="contained" color="primary" className={classes.button}>
          {children ? children : <StarBorderIcon />}
        </Button>
   
    </ThemeProvider>
  );
}
