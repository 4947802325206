import React, { useState, useEffect, useReducer } from "react";

// React Router
import { useHistory, useParams } from "react-router-dom";

// Material UI
// import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

// Formik
import { useFormikContext, Formik, Form, Field } from "formik";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// App
import { db } from "../../../firebase/firebase";
import useFirestoreQuery from "../../../firebase/useFirestoreQuery";
import useUpdateDocRef from "../../../firebase/useUpdateDocRef";
import { CustomFormikTextField } from "../../GridUtils/CustomFormikTextField/CustomFormikTextField";
import Footer from "../../Footer/Footer";
import LoadingCards from "../../loading/LoadingCards";

import {
  PTSerif,
  LaLuxesScript,
} from "../../Typography/LaLuxesScript/LaLuxesScript";

const initialState = { count: 0 };

function reducer(state, action) {
  switch (action.type) {
    case "increment":
      return { count: state.count + 1 };
    case "decrement":
      return { count: state.count - 1 };
    default:
      throw new Error();
  }
}

export default function Attendees() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [triggerSubmit, setTriggerSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  let { householdId } = useParams();
  let history = useHistory();

  // get persons of household
  const persons = useFirestoreQuery(
    householdId
      ? db.collection("persons").where("household", "==", householdId)
      : null
  );

  useEffect(() => {
    console.log("<Attendees state.count = ", state.count, " />");

    if (triggerSubmit && state.count === 0) {
      setTimeout(() => {
        history.push(`/rsvp/${householdId}/itinerary`);
      }, 1000);
    }
  }, [state]);

  function handleContinue(e) {
    setTriggerSubmit(true);
    setIsLoading(true);
  }

  if (persons.status === "loading") {
    return (
      <Box py={1} px={1}>
        <Container maxWidth="lg" disableGutters>
          <LoadingCards cards={1} waves={1} height={200} />
          <LoadingCards />
        </Container>
      </Box>
    );
  }

  if (persons.status === "error") {
    console.log(`Error: ${persons.error?.message}`);

    return (
      <Box py={1} px={1}>
        <Container maxWidth="lg" disableGutters>
          <Paper elevation={1}>
            <Box p={2}>
              <PTSerif>
                <Typography color="error" variant="h5">
                  An Error Occured. Please refresh your browser and try again.
                </Typography>
              </PTSerif>
            </Box>
          </Paper>
        </Container>
      </Box>
    );
  }

  return (
    <div>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="stretch"
        spacing={2}
      >
        <Grid item xs={12}>
          <Box py={1} px={1}>
            <Container maxWidth="lg" disableGutters>
              <Paper elevation={1}>
                <Box px={2} pt={2} pb={0}>
                  <LaLuxesScript bold>
                    <Typography color="error" variant="h1">
                      Attendees
                    </Typography>
                  </LaLuxesScript>

                  <PTSerif fontsize={16}>
                    <Typography color="secondary" variant="subtitle1" paragraph>
                      Due to venue restrictions, our wedding is RSVP only, and
                      we will only able be to accommodate those guests listed on
                      the invitation.
                    </Typography>
                  </PTSerif>

                  <Divider variant="middle" />

                  <PTSerif fontsize={24}>
                    <Typography color="secondary" variant="overline" paragraph>
                      Party of {persons.data.length}
                    </Typography>
                  </PTSerif>
                </Box>
              </Paper>
            </Container>
          </Box>
        </Grid>

        <Grid
          container
          item
          direction="column"
          justify="center"
          alignItems="stretch"
          spacing={2}
        >
          <Persons
            persons={persons.data}
            triggerSubmit={triggerSubmit}
            dispatch={dispatch}
          />
        </Grid>

        <Grid item xs={12}>
          <Box mx={1}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={isLoading ? null : handleContinue}
            >
              {isLoading ? (
                <CircularProgress color="inherit" size={28} />
              ) : (
                "CONTINUE"
              )}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <br></br>
      <Footer />
    </div>
  );
}

function getOrderFromAge(age, mobile) {
  let order = 0;

  switch (age) {
    case "ADULT":
      break;
    case "CHILD":
      order = order + 2;
      break;
    case "INFANT":
      order = order + 3;
      break;
    default:
      order = order + 4;
  }

  return mobile ? order : ++order;
}

const Persons = ({ persons, triggerSubmit, dispatch }) => {
  useEffect(() => {
    console.log("<Persons />");
  });

  useEffect(() => {
    console.log("<Persons /> ", "Updating Count.");

    var i;
    for (i = 0; i < persons.length; i++) {
      dispatch({ type: "increment" });
    }
  }, [persons.length]);

  return (
    <>
      {persons &&
        persons.map((person) => {
          return (
            <Grid
              style={{ order: getOrderFromAge(person.age, person.mobile) }}
              key={person.id}
              item
              xs={12}
            >
              <Person
                person={person}
                triggerSubmit={triggerSubmit}
                dispatch={dispatch}
              />
            </Grid>
          );
        })}
    </>
  );
};

const Person = ({ person, triggerSubmit, isUpdating, dispatch }) => {
  const [update, personDocUpdated] = useUpdateDocRef(person.ref, true);

  const initialPt2Values = {
    full_name: person.full_name || "",
    age: person.age || "",
    mobile: person.mobile || "",
    attendance: person.wedding_invite_pt2?.attendance || "",
    dietary_restrictions: person.wedding_invite_pt2?.dietary_restrictions || "",
    drink_preference: person.wedding_invite_pt2?.drink_preference || "",
  };

  const initialValues = {
    full_name: person.full_name || "",
    age: person.age || "",
    mobile: person.mobile || "",
    attendance: person.wedding_invite_pt2?.attendance || "",
    dietary_restrictions:
      person.wedding_invite_pt2?.dietary_restrictions ||
      person.wedding_invite?.dietary_restrictions ||
      "",
    drink_preference:
      person.wedding_invite_pt2?.drink_preference ||
      person.wedding_invite?.drink_preference ||
      "",
  };

  const onSubmit = (values, actions) => {
    update({
      full_name: values.full_name,
      age: values.age,
      mobile: values.mobile,
      wedding_invite_pt2: {
        attendance: values.attendance,
        dietary_restrictions: values.dietary_restrictions,
        drink_preference: values.drink_preference,
      },
    });
  };

  useEffect(() => {
    console.log("<Person />");
  });

  useEffect(() => {
    console.log("<Person(personDocUpdated) = ", personDocUpdated, " />");

    // When finished updating flag for parent
    if (triggerSubmit && personDocUpdated) {
      dispatch({ type: "decrement" });
    }
  }, [personDocUpdated]);

  return (
    <Box py={1} px={1}>
      <Container maxWidth="lg" disableGutters>
        <Paper elevation={1}>
          <Box p={2}>
            <Formik
              enableReinitialize={true}
              initialValues={
                person.wedding_invite_pt2 ? initialPt2Values : initialValues
              }
              onSubmit={onSubmit}
            >
              <Form>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="stretch"
                  spacing={2}
                >
                  <Grid item xs={12}>
                    <CustomFormikTextField
                      label="Guest Name"
                      name="full_name"
                      placeholder="Guest"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <AgeSelectField name="age" label="Age" />
                  </Grid>

                  <Grid item xs={12}>
                    <AttendenceSelectField
                      name="attendance"
                      label="Attendance"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <DrinkPreferenceSelectField
                      name="drink_preference"
                      label="Drink Preference"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <CustomFormikTextField
                        label="Dietary Restrictions"
                        name="dietary_restrictions"
                        placeholder="Dairy, Eggs, Gluten, etc..."
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <SumbitOnTrigger triggerSubmit={triggerSubmit} />
              </Form>
            </Formik>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

const SumbitOnTrigger = ({ triggerSubmit }) => {
  // Grab submitForm from context
  const { submitForm } = useFormikContext();

  useEffect(() => {
    // Submit the form when triggered by parent
    if (triggerSubmit) {
      submitForm();
      console.log("<SumbitOnTrigger(triggerSubmit) />");
    }
  }, [triggerSubmit, submitForm]);

  return null;
};

// user, child, baby
const Adornment = ({ icon }) => (
  <Box color="text.disabled">
    <FontAwesomeIcon icon={["fas", `${icon}`]} size="1x" fixedWidth />
  </Box>
);

const AgeSelectField = ({ name, label, id }) => {
  return (
    <Field name={name}>
      {(props) => {
        const { field } = props;
        return (
          <FormControl fullWidth variant="outlined">
            <InputLabel id="id-age-label">{label}</InputLabel>
            <Select
              id={id || name}
              labelId="id-age-label"
              label={label}
              {...field}
            >
              <MenuItem value={"ADULT"}>
                <MenuItemContext
                  icon="user"
                  color="text.disabled"
                  text="Adult"
                  size="xs"
                />
              </MenuItem>
              <MenuItem value={"CHILD"}>
                <MenuItemContext
                  icon="child"
                  color="text.disabled"
                  text="Child"
                  size="xs"
                />
              </MenuItem>
              <MenuItem value={"INFANT"}>
                <MenuItemContext
                  icon="baby"
                  color="text.disabled"
                  text="Infant"
                  size="xs"
                />
              </MenuItem>
            </Select>
          </FormControl>
        );
      }}
    </Field>
  );
};

const AttendenceSelectField = ({ name, label, id }) => {
  return (
    <Field name={name}>
      {(props) => {
        const { field } = props;
        return (
          <FormControl fullWidth variant="outlined">
            <InputLabel id="id-attendence-label">{label}</InputLabel>
            <Select
              id={id || name}
              labelId="id-attendence-label"
              label={label}
              {...field}
            >
              <MenuItem value={"attending"}>
                <MenuItemContext
                  icon="island-tropical"
                  color="text.disabled"
                  text="Joyfully Accepts"
                  size="xs"
                />
              </MenuItem>
              <MenuItem value={"declined"}>
                <MenuItemContext
                  icon="times-circle"
                  color="text.disabled"
                  text="Regretfully Declines"
                  size="xs"
                />
              </MenuItem>
            </Select>
          </FormControl>
        );
      }}
    </Field>
  );
};

const DrinkPreferenceSelectField = ({ name, label, id }) => {
  return (
    <Field name={name}>
      {(props) => {
        const { field } = props;
        return (
          <FormControl fullWidth variant="outlined">
            <InputLabel id="id-drink-preference-label">{label}</InputLabel>
            <Select
              id={id || name}
              labelId="id-drink-preference-label"
              label={label}
              {...field}
            >
              <MenuItem value="0">
                <em>None</em>
              </MenuItem>

              <MenuItem value={"NA"}>
                <MenuItemContext
                  color="text.disabled"
                  text="Non-Alcoholic"
                  size="xs"
                />
              </MenuItem>

              <MenuItem value={"RW"}>
                <MenuItemContext
                  color="text.disabled"
                  text="Red Wine"
                  size="xs"
                />
              </MenuItem>

              <MenuItem value={"WW"}>
                <MenuItemContext
                  color="text.disabled"
                  text="White Wine"
                  size="xs"
                />
              </MenuItem>

              <MenuItem value={"BE"}>
                <MenuItemContext color="text.disabled" text="Beer" size="xs" />
              </MenuItem>

              <MenuItem value={"WH"}>
                <MenuItemContext
                  color="text.disabled"
                  text="Whiskey"
                  size="xs"
                />
              </MenuItem>

              <MenuItem value={"VK"}>
                <MenuItemContext color="text.disabled" text="Vodka" size="xs" />
              </MenuItem>

              <MenuItem value={"RM"}>
                <MenuItemContext color="text.disabled" text="Rum" size="xs" />
              </MenuItem>

              <MenuItem value={"TQ"}>
                <MenuItemContext
                  color="text.disabled"
                  text="Tequila"
                  size="xs"
                />
              </MenuItem>

              <MenuItem value={"GI"}>
                <MenuItemContext color="text.disabled" text="Gin" size="xs" />
              </MenuItem>

              <MenuItem value={"BN"}>
                <MenuItemContext
                  color="text.disabled"
                  text="Bourbon"
                  size="xs"
                />
              </MenuItem>
            </Select>
          </FormControl>
        );
      }}
    </Field>
  );
};

const MenuItemContext = ({ icon, text, color, size }) => {
  return (
    <Grid container direction="row" justify="flex-start" alignItems="center">
      <Grid item>
        <Box mr={1} color={color || "text.primary"}>
          {icon && (
            <FontAwesomeIcon
              icon={["fas", `${icon}`]}
              size={size || "1x"}
              fixedWidth
            />
          )}
        </Box>
      </Grid>

      <Grid item align="left">
        <Typography>{text}</Typography>
      </Grid>
    </Grid>
  );
};
