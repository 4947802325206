import React from "react";

// App
import { LaLuxesSerif } from "../../Typography/LaLuxesScript/LaLuxesScript";

// React Router
import { Link } from "react-router-dom";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Material UI
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Fade from "@material-ui/core/Fade";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const Flexbox = withStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    alignItems: "center",
  },
})(Box);

const Item = withStyles({})(Box);

export default function FadeMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="fade-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Box color="primary.contrastText" p={1}>
          <FontAwesomeIcon icon={["fal", "bars"]} size="2x" />
        </Box>
      </Button>

      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={handleClose}>
          <Link to="/" style={{ textDecoration: "none" }}>
            <LaLuxesSerif fontsize={14}>
              <Flexbox>
                <Item px={1}>
                  <Typography color="secondary" ariant="body1">
                    <FontAwesomeIcon
                      icon={["far", "rings-wedding"]}
                      size="1x"
                      fixedWidth
                    />
                  </Typography>
                </Item>
                <Item px={1}>
                  <Typography color="secondary" variant="body1">
                    Home
                  </Typography>
                </Item>
              </Flexbox>
            </LaLuxesSerif>
          </Link>
        </MenuItem>

        <MenuItem onClick={handleClose}>
          <Link to="/travel" style={{ textDecoration: "none" }}>
            <LaLuxesSerif fontsize={14}>
              <Flexbox>
                <Item px={1}>
                  <Typography color="secondary" variant="body1">
                    <FontAwesomeIcon
                      icon={["far", "suitcase-rolling"]}
                      size="1x"
                      fixedWidth
                    />
                  </Typography>
                </Item>
                <Item px={1}>
                  <Typography color="secondary" variant="body1">
                    Travel
                  </Typography>
                </Item>
              </Flexbox>
            </LaLuxesSerif>
          </Link>
        </MenuItem>

        <MenuItem onClick={handleClose}>
          <Link to="/rsvp" style={{ textDecoration: "none" }}>
            <LaLuxesSerif fontsize={14}>
              <Flexbox>
                <Item px={1}>
                  <Typography color="secondary" variant="body1">
                    <FontAwesomeIcon
                      icon={["far", "envelope"]}
                      size="1x"
                      fixedWidth
                    />
                  </Typography>
                </Item>
                <Item px={1}>
                  <Typography color="secondary" variant="body1">
                    RSVP
                  </Typography>
                </Item>
              </Flexbox>
            </LaLuxesSerif>
          </Link>
        </MenuItem>

        {/* <MenuItem onClick={handleClose}>
          <Link to="/faqs" style={{ textDecoration: "none" }}>
            <LaLuxesSerif fontsize={14}>
              <Flexbox>
                <Item px={1}>
                  <Typography color="secondary" variant="body1">
                    <FontAwesomeIcon
                      icon={["far", "question-square"]}
                      size="1x"
                      fixedWidth
                    />
                  </Typography>
                </Item>
                <Item px={1}>
                  <Typography color="secondary" variant="body1">
                    FAQs
                  </Typography>
                </Item>
              </Flexbox>
            </LaLuxesSerif>
          </Link>
        </MenuItem> */}
      </Menu>
    </div>
  );
}
