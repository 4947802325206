import React from "react";

// App
import {
  PTSerif,
  LaLuxesSerif,
} from "../../Typography/LaLuxesScript/LaLuxesScript";

// Material UI
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

// images
// import leaf from "../../../images/png/leaf.png";
// import palmTrees from "../../../images/png/cirular_palm_trees_night.png";
import vacation_sketch from "../../../images/png/vacation_sketches_002.png";
import vacation_sketch_btm from "../../../images/png/vacation_sketches_002_btm.png";
import surfboard from "../../../images/png/surfboard_side_view.png";

import westElm from "../../../images/registry/png/west_elm_logo.png";
import potterybarn from "../../../images/registry/svg/pottery_barn.svg";
import amazon from "../../..//images/registry/png/amazon_logo.png";
import williamsSonoma from "../../../images/registry/svg/williams_sonoma.svg";

const CssGrid = withStyles({
  root: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    gridTemplateRows: "repeat(12, auto)",
  },
})(Container);

const Flexbox = withStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    alignItems: "center",
  },
})(Box);

const Item = withStyles({})(Box);

const useStyles = makeStyles((theme) => ({
  responsive: {
    width: "100%",
    height: "auto",
  },

  imageSurfBoard: {
    width: "60%",
    height: "auto",
  },
  imageSunrise: {
    width: "8rem",
    height: "auto",
  },

  imageStore1: {
    padding: "0.5rem",
    width: "auto",
    height: "2rem",
  },

  imageStore2: {
    padding: "0.5rem",
    width: "auto",
    height: "2rem",
  },

  imageStore3: {
    padding: "0.5rem",
    width: "auto",
    height: "2rem",
  },

  imageStore4: {
    padding: "0.5rem",
    width: "auto",
    height: "2rem",
  },
  imageEnd: {
    width: "90%",
    height: "auto",
  },

  cssGrid: {
    [theme.breakpoints.up("sm")]: {},

    [theme.breakpoints.up("md")]: {},

    [theme.breakpoints.up("lg")]: {},
  },

  itemTitle: {
    gridColumn: "1/-1",
    gridRow: "1",

    alignSelf: "center",
    justifySelf: "center",
  },
  itemBodyImage: {
    gridColumn: "1/3",
    gridRow: "2/3",

    textAlign: "left",
    alignSelf: "center",
    justifySelf: "center",
  },

  itemBody: {
    gridColumn: "3/-1",
    gridRow: "2",

    textAlign: "left",
    alignSelf: "center",
    justifySelf: "center",
  },

  itemStore1: {
    gridColumn: "1/-1",
    gridRow: "3",
  },

  itemStore2: {
    gridColumn: "1/-1",
    gridRow: "4",
  },

  itemStore3: {
    gridColumn: "1/-1",
    gridRow: "5",
  },

  itemStore4: {
    gridColumn: "1/-1",
    gridRow: "6",
  },
  itemImageEnd: {
    gridColumn: "1/-1",
    gridRow: "6/7",
  },
}));

export default function Registry() {
  const classes = useStyles({});

  return (
    <div id="id-wedding-registry">
      <CssGrid className={classes.cssGrid}>
        <Item className={classes.itemTitle} pt={3}>
          <Flexbox>
            <LaLuxesSerif fontsize={12}>
              <Typography color="error" variant="h2">
                REGIS<span style={{ textDecoration: "underline" }}>TRY</span>
              </Typography>
            </LaLuxesSerif>

            <Item pl={1}>
              <img
                loading="lazy"
                className={classes.imageSunrise}
                src={vacation_sketch}
                alt="vacation sketch"
              />
            </Item>
          </Flexbox>
        </Item>

        <Item className={classes.itemBodyImage}>
          <img
            loading="lazy"
            className={classes.imageSurfBoard}
            src={surfboard}
            alt="surfboard"
          />
        </Item>

        <Item className={classes.itemBody} pt={1}>
          <PTSerif fontsize={16}>
            <Typography color="secondary" variant="h5" paragraph>
              Your love, laughter, and company is all we could wish for on our
              special day.
            </Typography>
            <Typography color="secondary" variant="h5" paragraph>
              However, for our friends and family who have been asking for gift
              ideas, we've created online registries at the following retailers
            </Typography>
          </PTSerif>
        </Item>

        <Item className={classes.itemStore1} py={2}>
          <Button
            href="https://www.amazon.com/wedding/share/colynnandjeremy4eva"
            fullWidth
            variant="outlined"
          >
            <img
              loading="lazy"
              className={classes.imageStore1}
              src={amazon}
              alt="amazon"
            />
          </Button>
        </Item>

        <Item className={classes.itemStore2} py={2}>
          <Button
            href="https://www.potterybarn.com/registry/rjsszmb77f/registry-list.html"
            fullWidth
            variant="outlined"
          >
            <img
              loading="lazy"
              className={classes.imageStore2}
              src={potterybarn}
              alt="potterybarn"
            />
          </Button>
        </Item>

        <Item className={classes.itemStore3} py={2}>
          <Button
            href="https://www.williams-sonoma.com/registry/rjsszmb77f/registry-list.html"
            fullWidth
            variant="outlined"
          >
            <img
              loading="lazy"
              className={classes.imageStore3}
              src={williamsSonoma}
              alt="williams sonoma"
            />
          </Button>
        </Item>

        <Item className={classes.itemStore4} py={2}>
          <Button
            href="https://www.westelm.com/registry/rjsszmb77f/registry-list.html"
            fullWidth
            variant="outlined"
          >
            <img
              loading="lazy"
              className={classes.imageStore4}
              src={westElm}
              alt="west elm"
            />
          </Button>
        </Item>

        <Item className={classes.itemImageEnd} py={3}>
          <img
            loading="lazy"
            className={classes.imageEnd}
            src={vacation_sketch_btm}
            alt="west elm"
          />
        </Item>
      </CssGrid>
    </div>
  );
}
