import "./App.css";

// App
import LandingPage from "./views/LandingPage";
import Travel from "./views/Travel";
import Rsvp from "./views/Rsvp";
import Faqs from "./views/Faqs";
import { ProvideSnackbar } from "./components/CustomizedSnackBars/CustomizedSnackBars";

// Material UI
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";

// Material UI Fonts
import "fontsource-roboto"; // Defaults to weight 400 with all styles included.
import "fontsource-roboto/300.css";

// React Router
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fas } from "@fortawesome/pro-solid-svg-icons";

library.add(fal, far, fas);

let theme = createMuiTheme({
  typography: {
    fontSize: 16,

    body1: {
      fontWeight: 100,
      lineHeight: 1.7,
      letterSpacing: "0.00990em",
    },
  },
});

// Typography font sizes in the theme responsive.
theme = responsiveFontSizes(theme);

function App() {
  return (
    <Router>
      <div className="App">
        <ThemeProvider theme={theme}>
          <ProvideSnackbar>
            <Switch>
              <Route path="/faqs">
                <Faqs />
              </Route>
              <Route path="/rsvp">
                <Rsvp />
              </Route>
              <Route path="/travel">
                <Travel />
              </Route>
              <Route path="/">
                <LandingPage />
              </Route>
            </Switch>
          </ProvideSnackbar>
        </ThemeProvider>
      </div>
    </Router>
  );
}

export default App;
