// App
import {
  PTSerif,
} from "../../Typography/LaLuxesScript/LaLuxesScript";



// Material UI
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from "@material-ui/core/Button";

// images
import background from "../../../images/png/vacaction_sand_background_1260w.png";

const Grid = withStyles({
  root: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
  },
})(Container);

const Flexbox = withStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    alignItems: "center",
  },
})(Box);

const Item = withStyles({})(Box);

const useStyles = makeStyles((theme) => ({
  responsive: {
    width: "100%",
    height: "auto",
  },
  cssGrid: {
    [theme.breakpoints.up("sm")]: {},

    [theme.breakpoints.up("md")]: {},

    [theme.breakpoints.up("lg")]: {},
  },

  itemTopBackgroundImage: {
    gridColumn: "1/-1",
    gridRow: "1/4",

    alignSelf: "center",
    justifySelf: "center",
  },

  itemTitle: {
    gridColumn: "1/-1",
    gridRow: "1",

    alignSelf: "center",
    justifySelf: "center",
  },

  itemBody1: {
    visibility: "hidden",
    padding: theme.spacing(2),
    gridColumn: "1/-1",
    gridRow: "1",
  },

  itemBody2: {
    padding: theme.spacing(2),
    gridColumn: "1/-1",
    gridRow: "2",
  },

  itemBody3: {
    padding: theme.spacing(2),
    gridColumn: "1/-1",
    gridRow: "3",
  },

  itemNumber: {
    gridColumn: "1/-1",
    gridRow: "3",
  },
}));

export default function Rentals() {
  const classes = useStyles({});
  const atMd = useMediaQuery("(min-width:960px)");

  return (
    <>
      <Grid className={classes.cssGrid} maxWidth="lg" disableGutters>
        <Item className={classes.itemTopBackgroundImage}>
          <picture>
            <source type="image/webp" srcSet={background} />
            <img
              loading="lazy"
              className={classes.responsive}
              src={background}
              alt="sand background"
            />
          </picture>
        </Item>

        <Item className={classes.itemBody1}>
          <PTSerif fontsize={18}>
            <Typography color="secondary" variant="h5" paragraph>
              There are also several properties available on Airbnb and VRBO
              near the venue that are great for larger groups of people. We
              recommend booking them early to ensure availability.
            </Typography>
          </PTSerif>
        </Item>

        <Item className={classes.itemBody2}>
          <PTSerif fontsize={18}>
            <Typography color="secondary" variant="h5" paragraph>
              There are also several properties available on Airbnb and VRBO
              near the venue that are great for larger groups of people. We
              recommend booking them early to ensure availability.
            </Typography>
          </PTSerif>
        </Item>

        <Item className={classes.itemBody3}>
          <PTSerif fontsize={18}>
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              href="https://www.airbnb.com/s/75831-Overseas-Highway--Islamorada--FL--USA/homes?current_tab_id=all_tab&selected_tab_id=all_tab&refinement_paths%5B%5D=%2Fhomes&source=mc_search_bar&search_type=unknown&screen_size=large&search_by_map=true&hide_dates_and_guests_filters=true&checkin=2021-03-19&checkout=2021-03-22&adults=2&ne_lat=25.174610059017212&ne_lng=-80.58182980643011&sw_lat=24.525376307083302&sw_lng=-80.89288022146917&zoom=11"
            >
              AIRBNB
            </Button>
            <br></br>
            <Box py={2}>
              <Button
                fullWidth
                variant="outlined"
                color="secondary"
                href="https://www.vrbo.com/search/keywords:75831-overseas-highway-islamorada-fl-usa/arrival:2021-03-19/departure:2021-03-22?adultsCount=2&petIncluded=false"
              >
                VRBO
              </Button>
            </Box>
          </PTSerif>
        </Item>
      </Grid>
    </>
  );
}
