import React from "react";

// Material UI
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import {
  red,
  green,
  lightBlue,
  orange,
  cyan,
  purple,
} from "@material-ui/core/colors";
import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import StarBorderIcon from "@material-ui/icons/StarBorder";

// Link to color picker
// https://material-ui.com/customization/color/

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(2),
    color: "white",
  },
}));

export default function ThemedFabutton({
  children,
  title,
  color,
  herf,
  shade = 500,
}) {
  const dict = {
    red: red[shade],
    green: green[shade],
    blue: lightBlue[shade],
    orange: orange[shade],
    cyan: cyan[shade],
    purple: purple[shade],
  };

  const blueTheme = createMuiTheme({
    palette: {
      primary: {
        main: dict[color],
      },
    },
  });

  const classes = useStyles();
  return (
    <ThemeProvider theme={blueTheme}>
      <Tooltip title={title} aria-label="add">
        <Fab href={herf} color="primary" className={classes.fab}>
          {children ? children : <StarBorderIcon />}
        </Fab>
      </Tooltip>
    </ThemeProvider>
  );
}
