import React, { useState, createContext, useContext } from "react";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const snackBarContext = createContext();

function useSnackBar() {
  return useContext(snackBarContext);
}

function ProvideSnackbar({ children }) {
  const context = useProvideSnackbar();
  return (
    <snackBarContext.Provider value={context}>
      <CustomizedSnackbars />
      {children}
    </snackBarContext.Provider>
  );
}

function useProvideSnackbar() {
  const [snackbar, setSnackbar] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    message: [],
    severity: "error", // error, warning, info, success
  });

  function set(context) {
    setSnackbar({
      ...snackbar,
      ...context,
      open: true,
    });
  }

  function close() {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  }

  return { snackbar, set, close };
}

const useSnackbarsStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const anchorOrigins = [
  { vertical: "top", horizontal: "left" },
  { vertical: "top", horizontal: "center" },
  { vertical: "top", horizontal: "right" },
  { vertical: "bottom", horizontal: "left" },
  { vertical: "bottom", horizontal: "center" },
  { vertical: "bottom", horizontal: "right" },
];

const getRandomInt = (max) => {
  return Math.floor(Math.random() * Math.floor(max));
};

function CustomizedSnackbars() {
  const defaultAnchor = anchorOrigins[getRandomInt(anchorOrigins.length)];
  const [anchor, setAnchor] = useState(defaultAnchor);

  const classes = useSnackbarsStyles();
  const context = useSnackBar();

  const { open, vertical, horizontal, message, severity } = context.snackbar;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    // Close Snackbar
    context.close();

    // Set new anchor position
    const newAnchor = anchorOrigins[getRandomInt(anchorOrigins.length)];
    setAnchor(newAnchor);
  };

  return (
    <div className={classes.root}>
      {severity ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={`${severity}`}>
            {message.map((line, index) => (
              <span key={index}>
                {line}
                <br></br>
              </span>
            ))}
          </Alert>
        </Snackbar>
      ) : (
        <Snackbar
          anchorOrigin={{
            vertical: anchor.vertical,
            horizontal: anchor.horizontal,
          }}
          open={open}
          autoHideDuration={4000}
          onClose={handleClose}
          message={message.map((line, index) => (
            <span key={index}>
              {line}
              <br></br>
            </span>
          ))}
          action={
            <>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          }
        />
      )}
    </div>
  );
}

export { useSnackBar, ProvideSnackbar };
