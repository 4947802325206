import React from "react";

import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";

export default function LoadingCards({ cards = 3, waves = 4, height = 70 }) {
  const cardsDict = [];
  for (let i = 0; i < cards; i++) {
    cardsDict.push({ i });
  }

  const wavesDict = [];
  for (let i = 0; i < waves; i++) {
    wavesDict.push({ i });
  }

  return (
    <div>
      {cardsDict.map((block, index) => {
        return (
          <Box key={index} p={2}>
            <Paper elevation={1}>
              <Box p={2}>
                {wavesDict.map((wave, index) => {
                  return (
                    <Skeleton
                      key={index}
                      animation="wave"
                      weight="auto"
                      height={height}
                    />
                  );
                })}
              </Box>
            </Paper>
          </Box>
        );
      })}
    </div>
  );
}
