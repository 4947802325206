import React, { useState, useEffect, useReducer } from "react";

// React Router
import { useHistory, useParams } from "react-router-dom";

// Material UI
// import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

// Formik
import { useFormikContext, Formik, Form, Field } from "formik";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// App
import { db } from "../../../firebase/firebase";
import useFirestoreQuery from "../../../firebase/useFirestoreQuery";
import useUpdateDocRef from "../../../firebase/useUpdateDocRef";
import Footer from "../../Footer/Footer";
import LoadingCards from "../../loading/LoadingCards";

import {
  PTSerif,
  LaLuxesScript,
} from "../../Typography/LaLuxesScript/LaLuxesScript";

const initialState = { count: 1 };

function reducer(state, action) {
  switch (action.type) {
    case "increment":
      return { count: state.count + 1 };
    case "decrement":
      return { count: state.count - 1 };
    default:
      throw new Error();
  }
}

export default function Itinerary() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [triggerSubmit, setTriggerSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  let { householdId } = useParams();
  let history = useHistory();

  // get persons of household
  const household = useFirestoreQuery(
    householdId ? db.collection("households").doc(householdId) : null
  );

  useEffect(() => {
    console.log("<Itinerary />");
  });

  useEffect(() => {
    console.log("<Itinerary state.count = ", state.count, " />");

    if (triggerSubmit && state.count === 0) {
      setTimeout(() => {
        history.push(`/rsvp/${householdId}/confirmation`);
      }, 1000);
    }
  }, [state]);

  function handleContinue(e) {
    setTriggerSubmit(true);
    setIsLoading(true);
  }

  if (household.status === "loading") {
    return (
      <Box py={1} px={1}>
        <Container maxWidth="lg" disableGutters>
          <LoadingCards cards={1} waves={1} height={200} />
          <LoadingCards cards={1} waves={3} />
        </Container>
      </Box>
    );
  }

  return (
    <div>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="stretch"
        spacing={2}
      >
        <Grid item xs={12}>
          <Box py={1} px={1}>
            <Container maxWidth="lg" disableGutters>
              <Paper elevation={1}>
                <Box p={2}>
                  <LaLuxesScript bold>
                    <Typography color="error" variant="h1">
                      Itinerary
                    </Typography>
                  </LaLuxesScript>

                  <PTSerif fontsize={16}>
                    <Typography color="secondary" variant="subtitle1" paragraph>
                      Share a little about your travel to help in the
                      coordination of activities and transportation.
                    </Typography>
                  </PTSerif>
                </Box>
              </Paper>
            </Container>
          </Box>
        </Grid>

        <Grid item>
          <Travel
            household={household.data}
            triggerSubmit={triggerSubmit}
            dispatch={dispatch}
          />
        </Grid>

        <Grid item xs={12}>
          <Box mx={1}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={isLoading ? null : handleContinue}
            >
              {isLoading ? (
                <CircularProgress color="inherit" size={28} />
              ) : (
                "CONTINUE"
              )}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <br></br>
      <Footer />
    </div>
  );
}

const Travel = ({ household, triggerSubmit, dispatch }) => {
  const [update, householdDocUpdated] = useUpdateDocRef(household.ref);

  const initialValues = {
    arrival_date: household.arrival_date || "",
    departure_date: household.departure_date || "",
    lodging: household.lodging || "",
  };

  const onSubmit = (values, actions) => {
    update(values);
  };

  useEffect(() => {
    console.log("<Travel(householdDocUpdated) = ", householdDocUpdated, " />");

    // When finished updating flag for parent
    if (triggerSubmit && householdDocUpdated) {
      dispatch({ type: "decrement" });
    }
  }, [householdDocUpdated]);

  useEffect(() => {
    console.log("<Travel />");
  });

  return (
    <Box py={1} px={1}>
      <Container maxWidth="lg" disableGutters>
        <Paper elevation={1}>
          <Box p={2}>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              onSubmit={onSubmit}
            >
              <Form>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="stretch"
                  spacing={2}
                >
                  <Grid item xs={12}>
                    <ArrivalDateSelectField
                      label="Arriving"
                      name="arrival_date"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <DepartureDateSelectField
                      label="Departing"
                      name="departure_date"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <LodgingSelectField label="Lodging" name="lodging" />
                  </Grid>
                </Grid>

                <SumbitOnTrigger triggerSubmit={triggerSubmit} />
              </Form>
            </Formik>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

const SumbitOnTrigger = ({ triggerSubmit }) => {
  // Grab submitForm from context
  const { submitForm } = useFormikContext();

  useEffect(() => {
    // Submit the form when triggered by parent
    if (triggerSubmit) {
      submitForm();
      console.log("<SumbitOnTrigger(triggerSubmit) />");
    }
  }, [triggerSubmit, submitForm]);

  return null;
};

const LodgingSelectField = ({ name, label, id }) => {
  return (
    <Field name={name}>
      {(props) => {
        const { field } = props;
        return (
          <FormControl fullWidth variant="outlined">
            <InputLabel id="id-lodging-label">{label}</InputLabel>
            <Select
              id={id || name}
              labelId="id-lodging-label"
              label={label}
              {...field}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>

              <MenuItem value={"amara"}>
                <MenuItemContext
                  color="text.disabled"
                  text="Amara Cay Resort"
                  size="xs"
                />
              </MenuItem>

              <MenuItem value={"siesta"}>
                <MenuItemContext
                  color="text.disabled"
                  text="La Siesta Resort &amp; Marina"
                  size="xs"
                />
              </MenuItem>

              <MenuItem value={"oceanside"}>
                <MenuItemContext
                  color="text.disabled"
                  text="Islander Resort - Oceanside"
                  size="xs"
                />
              </MenuItem>

              <MenuItem value={"breezy"}>
                <MenuItemContext
                  color="text.disabled"
                  text="Breezy Palms Resort"
                  size="xs"
                />
              </MenuItem>

              <MenuItem value={"airbnd"}>
                <MenuItemContext
                  color="text.disabled"
                  text="Airbnb/VRBO"
                  size="xs"
                />
              </MenuItem>
            </Select>
          </FormControl>
        );
      }}
    </Field>
  );
};

const ArrivalDateSelectField = ({ name, label, id }) => {
  return (
    <Field name={name}>
      {(props) => {
        const { field } = props;
        return (
          <FormControl fullWidth variant="outlined">
            <InputLabel id="id-arrival-date-label">{label}</InputLabel>
            <Select
              id={id || name}
              labelId="id-arrival-date-label"
              label={label}
              {...field}
            >
              <MenuItem value="0">
                <em>None</em>
              </MenuItem>

              <MenuItem value={"17"}>
                <MenuItemContext
                  color="text.disabled"
                  text="WED 3/17"
                  size="xs"
                />
              </MenuItem>

              <MenuItem value={"18"}>
                <MenuItemContext
                  color="text.disabled"
                  text="THU 3/18"
                  size="xs"
                />
              </MenuItem>

              <MenuItem value={"19"}>
                <MenuItemContext
                  color="text.disabled"
                  text="FRI 3/19"
                  size="xs"
                />
              </MenuItem>

              <MenuItem value={"20"}>
                <MenuItemContext
                  color="text.disabled"
                  text="SAT 3/20"
                  size="xs"
                />
              </MenuItem>

              <MenuItem value={"21"}>
                <MenuItemContext
                  color="text.disabled"
                  text="SUN 3/21"
                  size="xs"
                />
              </MenuItem>
            </Select>
          </FormControl>
        );
      }}
    </Field>
  );
};

const DepartureDateSelectField = ({ name, label, id }) => {
  return (
    <Field name={name}>
      {(props) => {
        const { field } = props;
        return (
          <FormControl fullWidth variant="outlined">
            <InputLabel id="id-departure-date-label">{label}</InputLabel>
            <Select
              id={id || name}
              labelId="id-departure-date-label"
              label={label}
              {...field}
            >
              <MenuItem value="0">
                <em>None</em>
              </MenuItem>

              <MenuItem value={"21"}>
                <MenuItemContext
                  color="text.disabled"
                  text="SUN 3/21"
                  size="xs"
                />
              </MenuItem>

              <MenuItem value={"22"}>
                <MenuItemContext
                  color="text.disabled"
                  text="MON 3/22"
                  size="xs"
                />
              </MenuItem>

              <MenuItem value={"23"}>
                <MenuItemContext
                  color="text.disabled"
                  text="TUE 3/23"
                  size="xs"
                />
              </MenuItem>

              <MenuItem value={"24"}>
                <MenuItemContext
                  color="text.disabled"
                  text="WED 3/24"
                  size="xs"
                />
              </MenuItem>

              <MenuItem value={"25"}>
                <MenuItemContext
                  color="text.disabled"
                  text="THU 3/25"
                  size="xs"
                />
              </MenuItem>
            </Select>
          </FormControl>
        );
      }}
    </Field>
  );
};

const MenuItemContext = ({ icon, text, color, size }) => {
  return (
    <Grid container direction="row" justify="flex-start" alignItems="center">
      <Grid item>
        <Box mr={1} color={color || "text.primary"}>
          {icon && (
            <FontAwesomeIcon
              icon={["fas", `${icon}`]}
              size={size || "1x"}
              fixedWidth
            />
          )}
        </Box>
      </Grid>

      <Grid item align="left">
        <Typography>{text}</Typography>
      </Grid>
    </Grid>
  );
};
