// firebase
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";

// Public API keys
const firebaseConfig = {
  apiKey: "AIzaSyCE-LsT3JDYkh_l9Tjji9jdamNl830hS-0",
  authDomain: "cj-wedding-project.firebaseapp.com",
  databaseURL: "https://cj-wedding-project.firebaseio.com",
  projectId: "cj-wedding-project",
  storageBucket: "cj-wedding-project.appspot.com",
  messagingSenderId: "220538593752",
  appId: "1:220538593752:web:f80700ca04808ba4f412ad",
  measurementId: "G-31YHW0S3G3",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const functions = firebase.functions();

export { db, firebase, functions };
