import React from "react";

// App
import FadeMenu from "../FadeMenu/FadeMenu";

// Font Awesome
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Material UI
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
// import Typography from "@material-ui/core/Typography";

// images
// import image1 from "../../../images/rsvp/la_siesta_resort_beach/la_siesta_resort_beach_1050w_overlay.jpg";
// import image1_webp from "../../../images/rsvp/la_siesta_resort_beach/la_siesta_resort_beach_1050w_overlay.webp";

const CssGrid = withStyles({
  root: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
  },
})(Container);

const Item = withStyles({})(Box);

const useStyles = makeStyles((theme) => ({
  image: {
    width: "100%",
    height: "auto",
    justifyItems: "center",
    alignItems: "center",
    background:
      "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)",

    [theme.breakpoints.up("md")]: {},
  },
  cssGrid: {
    [theme.breakpoints.up("sm")]: {},

    [theme.breakpoints.up("md")]: {},

    [theme.breakpoints.up("lg")]: {},
  },
  imageItem: {
    gridColumn: "1/-1",
    gridRow: "1/-1",
  },
  hamburger: {
    gridColumn: "1/-1",
    gridRow: "1",
    justifySelf: "end",
  },
  title: {
    gridColumn: "1/-1",
    gridRow: "1/-1",

    alignSelf: "center",
    justifySelf: "center",
  },
  subtitle: {
    gridColumn: "1/-1",
    gridRow: "3",
  },
  menu: {
    marginTop: theme.spacing(-5),
    "& > *": {},
  },
}));

export default function RsvpHeroWithMenu() {
  const classes = useStyles({});

  return (
    <div>
      <CssGrid maxWidth={false} disableGutters>
        <Item className={classes.hamburger}>
          <FadeMenu />
        </Item>
      </CssGrid>
    </div>
  );
}
