import React from "react";

// Material UI
import Box from "@material-ui/core/Box";

// React Router
import { Switch, Route, useRouteMatch } from "react-router-dom";

// App
import RsvpHeroWithMenu from "../components/Headers/RsvpHeroWithMenu/RsvpHeroWithMenu";
import FindRsvp from "../components/Section/rsvp/FindRsvp/FindRsvp";

// images
import background from "../images/rsvp/shadow-overlay-color.png";
// import background2 from "../images/rsvp/shadow-overlay.png";
// import background_webp from "../images/rsvp/background_1942w.webp";
import Attendees from "../components/Section/rsvp/Attendees";
import Itinerary from "../components/Section/rsvp/Itinerary";
import Confirmation from "../components/Section/rsvp/Confirmation";
import Thanks from "../components/Section/rsvp/Thanks";

export default function Rsvp() {
  let match = useRouteMatch();

  return (
    <div
      style={{
        background: `url(${background})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <header>
        <Box mx={0}>
          <RsvpHeroWithMenu />
        </Box>
      </header>

      <Switch>
        <Route path={`${match.path}/:householdId/attendees`}>
          <Attendees />
        </Route>
        <Route path={`${match.path}/:householdId/itinerary`}>
          <Itinerary />
        </Route>
        <Route path={`${match.path}/:householdId/confirmation`}>
          <Confirmation />
        </Route>
        <Route path={`${match.path}/:householdId/thanks`}>
          <Thanks />
        </Route>
        <Route path={match.path}>
          <FindRsvp />
        </Route>
      </Switch>
    </div>
  );
}
